<template>
  <div>
    <v-sheet style="border-radius: 20px;">
      <v-card-title style="height: 14vh;"><v-spacer></v-spacer>
        <template>
          <div>
            <p class=" font-weight-bold text-uppercase" v-t="'charts-page[\'rec-efficiency-rate-history\']'"
              style="font-size:1.1rem; display: inline; white-space: nowrap;"><b>
              </b></p>
            <p class="font-weight-bold text-uppercase" style="font-size:1.2rem; display: inline; white-space: nowrap;">
              <b>&nbsp;
                {{ " - " + selectedBin }}&nbsp;</b>
            </p>
          </div>
          <div>
            <v-avatar><!--<v-icon @click="flippingCards[0].flipped = true">mdi-information</v-icon>--><v-icon @click.stop="
              selectedBin = 'station';
            plotDataRecRateBins(recRateGraphData);
            ">mdi-refresh</v-icon></v-avatar>
          </div>
        </template>
        <v-spacer></v-spacer></v-card-title>
      <v-divider thickness="10px"></v-divider>

      <v-sheet style="border-radius: 20px;">
        <v-card-text>
          <v-row v-show="(lineChartBinConfig.options &&
            recRateGraphData.length > 0 &&
            !loading) ||
            updating
            ">
            <v-col cols="1" class="d-flex justify-center align-center">
              <v-btn icon @click="addDayAndUpdate(true)">
                <v-icon size="45">mdi-menu-left</v-icon></v-btn></v-col>

            <v-col name="graph-col" cols="10">
              <apexchart ref="chart" type="line" :options="lineChartBinConfig.options"
                :series="lineChartBinConfig.series">
              </apexchart>
              <v-progress-linear indeterminate size="55" v-if="updating" class="mx-auto"
                style="width: 50%"></v-progress-linear>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="1">
              <v-btn icon @click="addDayAndUpdate" class="d-flex justify-center align-center"><v-icon
                  size="45">mdi-menu-right</v-icon></v-btn></v-col>
          </v-row>

          <v-alert v-if="recRateGraphData.length == 0 && !loading && !updating" outlined dismissible type="error"
            class="mx-auto" max-width="250px">
            <span v-t="'no-results-generic'"></span>
          </v-alert>
          <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        </v-card-text>
      </v-sheet>
    </v-sheet>
    <!--<transition name="flip">
      <v-sheet style="height: 100%" v-if="flippingCards[0].flipped">
        <v-card-title>
          <v-spacer></v-spacer>

          <template>
            <b v-t="'charts-page[\'rec-rate-history\']'"></b>
            <v-avatar>
              <v-btn icon @click="flippingCards[0].flipped = false">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn></v-avatar>
          </template>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <p v-t="'charts-page[\'rec-rate-history-desc\']'"></p>
        </v-card-text>
      </v-sheet>
    </transition>-->
  </div>
</template>


<script>
import { getRecyclingRate } from "../../utils/stations_api_client";

export default {
  props: ["selectedBin", "selectedStation"],
  async beforeMount() {
    this.refreshChart();
  },
  data() {
    return {
      recRateGraphData: [],
      lineChartBinConfig: {},
      flippingCards: [{ name: "history", flipped: false }],
      loading: true,
      updating: false,
      graphScrollingCounter: 0,
      graphScrollingUpdateThreshold: 5,
      initialScrollingPos: null,
      currentScrollingPos: null,
      dateArr: [],
    };
  },
  watch: {
    selectedBin: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val) {
        // do your stuff
        //console.log(val, oldVal);
        if (val !== "station") this.filterGraph(val);
      },
    },

    selectedStation: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler() {
        // do your stuff
        // console.log(val);
        this.refreshChart();
      },
    },
    $graphsDates: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler() {
        // do your stuff
        this.dateArr = [];
        this.refreshChart();
      },
    },
  },

  methods: {
    refreshChart() {
      this.loading = true;
      this.selectedBin = "station";
      this.activePicker = false;
      this.getStationRecyclingRateTimeFramed(this.$selectedStation).then((stIdUpdated) => {
        if (stIdUpdated === this.$selectedStation?.StationId)
          this.loading = false;
      });
    },

    async getStationRecyclingRateTimeFramed(stationRec) {
      if (this.$graphsDates[0] != undefined && this.$graphsDates[1] != undefined) {
        this.recRateGraphData = [];

        const myApiData = await getRecyclingRate(
          stationRec["StationId"],
          this.$graphsDates[0],
          this.$graphsDates[1]
        );

        if (stationRec["StationId"] !== this.$selectedStation.StationId) return;

        this.recRateGraphData = myApiData;

        var endDate = new Date(this.$graphsDates[1]);
        var today = new Date();
        var diffDays = 0;
        if (endDate > today) {
          var diff = Math.abs(endDate - today);
          diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        }
        this.plotDataRecRateBins(this.recRateGraphData, diffDays);

        return stationRec?.StationId
      }
    },
    async getStationRecyclingRateTimeFramedUpdateSeries(stationRec, dates) {
      if (dates[0] != undefined && dates[1] != undefined) {
        this.updating = true;
        this.recRateGraphData = [];

        const apiResponseData = await getRecyclingRate(
          stationRec["StationId"],
          dates[0],
          dates[1]
        );

        this.recRateGraphData = apiResponseData;
        var endDate = new Date(this.$graphsDates[1]);
        var today = new Date();
        var diffDays = 0;
        if (endDate > today) {
          var diff = Math.abs(endDate - today);
          diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        }
        this.plotDataRecRateBins(this.recRateGraphData, diffDays);
        this.updating = false;
      }
    },
    filterGraph(target) {
      this.selectedBin = target; // Frontend refresh
      // Useful when user wants to filter entire data set by material (plastic, paper, etc.)
      //console.log(target);
      var filtered = this.recRateGraphData.filter(
        (value) => value.material_eng === target
      );
      this.plotDataRecRateBins(filtered, 0);
    },

    async addDayAndUpdate(back = false) {
      if (this.dateArr.length == 0) {
        this.dateArr = this.$graphsDates;
      }
      var dateStart = new Date(Date.parse(this.dateArr[0]));
      var dateEnd = new Date(Date.parse(this.dateArr[1]));
      dateEnd.setDate(dateEnd.getDate() + (back === true ? -1 : 1));
      dateStart.setDate(dateStart.getDate() + (back === true ? -1 : 1));
      this.dateArr = [];
      this.dateArr.push(
        dateStart.toISOString().substr(0, 10),
        dateEnd.toISOString().substr(0, 10)
      );
      this.graphScrollingCounter = 0;
      this.currentScrollingPos = null;
      this.initialScrollingPos = null;
      await this.getStationRecyclingRateTimeFramedUpdateSeries(
        this.$selectedStation,
        this.dateArr
      );
    },

    plotDataRecRateBins(data, forecastDays) {
      // Use this method to prepare data for plots.

      var totalForectastDays = forecastDays

      var categories = [];

      var labels = data.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.ref_date === value.ref_date)
      );
      var ds = data.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id_tiny === value.id_tiny)
      );

      //console.log(ds);
      labels.forEach((element, i) => {
        if (i % 1 === 0) {
          categories.push(element.ref_date);
        }
      });
      //console.log("labels");
      //console.log(labels);

      var tmpCat = categories;
      categories = tmpCat.sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });

      //console.log("categories");
      //console.log(categories);


      var datasets = [];
      const currentLanguage = this.$i18n.locale;
      ds.forEach((item, i) => {
        if (i % 1 === 0) {
          var material_name = currentLanguage == "it-IT" ? item.material_ita : item.material_eng
          var dataset_internal = {
            name: material_name,
            data: [],
          };

          categories.forEach((element) => {
            var record = data.filter(
              (value) =>
                value.material === item.material &&
                value.id_tiny === item.id_tiny &&
                value.ref_date === element
            );

            //console.log(record);
            if (record.length > 0) {
              dataset_internal.data.push(record[0].rec_rate);
            } else {
              dataset_internal.data.push(0);
            }
          });
          datasets.push(dataset_internal);
        }
      });

      /* REMEMBER: as shown in the guide, each time you have to update the graph,
       you have to redefine object from the outermost element. In this case: the root*/
      this.lineChartBinConfig = {
        options: {
          chart: {
            events: {
              click: async function (event, chartContext, config) {
                this.currentScrollingPos = event;
                //console.log(                  this.initialScrollingPos.screenX,                   this.currentScrollingPos.screenX                 );
                if (
                  this.graphScrollingCounter >=
                  this.graphScrollingUpdateThreshold &&
                  this.currentScrollingPos &&
                  this.initialScrollingPos
                ) {
                  //console.log(chartContext, config.config.xaxis);
                  //console.log(this.$graphsDates);

                  var firstDate = chartContext.data.twoDSeriesX[0];
                  var latestDate =
                    chartContext.data.twoDSeriesX[
                    chartContext.data.twoDSeriesX.length - 1
                    ];
                  //console.log(
                  //  firstDate,
                  // latestDate,
                  // config.config.xaxis.max,
                  // config.config.xaxis.min
                  //);

                  if (
                    config.config.xaxis.max >= latestDate &&
                    this.currentScrollingPos.screenX <
                    this.initialScrollingPos.screenX
                  ) {
                    this.addDayAndUpdate();
                  }

                  if (
                    config.config.xaxis.min <= firstDate &&
                    this.currentScrollingPos.screenX >
                    this.initialScrollingPos.screenX
                  ) {
                    this.addDayAndUpdate(true);
                  }
                }
              }.bind(this),
              mouseMove: function (event) {
                if (this.graphScrollingCounter == 0) {
                  this.initialScrollingPos = event;
                }
              }.bind(this),
              scrolled: function () {
                this.graphScrollingCounter++;
              }.bind(this),
            },
            id: "Segregation rate per period",
            type: "line",
            stacked: false,
            height: 350,
            zoom: {
              type: "x",
              enabled: true,
              autoScaleYaxis: true,
            },
            toolbar: {
              autoSelected: "zoom",
            },
          },
          forecastDataPoints: {
            count: totalForectastDays
          },
          yaxis: [
            {
              opposite: false,
              tickAmount: 5,
              max: 1,
              min: 0,
              labels: {
                opposite: true,
                formatter: function (val) {
                  return (val * 100).toFixed(2) + "%";
                },
                align: "right",
              },
              title: {
                text: "Segregation rate",
              },
            },
          ],
          xaxis: {
            type: "datetime",
            categories: categories,
            dataLabels: {},
            tickAmount: 30,
            labels: {
              show: true,
              style: {
                colors: "#000",
                fontSize: "15px",
                fontFamily: "Codec Pro, sans-serif",
              },
              format: "dd/MM",
            },
            axisBorder: { show: true },
            axisTicks: { show: true },
            tooltip: { enabled: true },
          },
          dataLabels: { enabled: false },
          stroke: {
            curve: "smooth",
          },
        },
        series: datasets,
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
        },
      };
    },
  },
  computed: {},
};
</script>
                        
<style></style>
                        
