<template>
  <v-app fill-height>
    <div class="page" style="height: 100%;">
      <v-row>
        <v-col xl="1.5" lg="1.5" md="1.5" sm="2" xs="0"></v-col>
        <v-col xl="10.5" lg="10.5" md="10.5" sm="10" xs="12">
          <app-bar></app-bar>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="1.5" lg="1.5" md="1.5" sm="2" xs="0"><nav-bar @update="onClickChild"></nav-bar></v-col>
        <v-col xl="10.5" lg="10.5" md="10.5" sm="10" xs="12">
          <v-main>
            <v-container class="fill-height ">
              <charts-page align="center" :style="!isMobileDevice ? 'width: auto; max-width: 80vw;' : ''"
                v-if="selectedPage == '0'"></charts-page>
              <admin-people :style="!isMobileDevice ? '' : ''" v-if="selectedPage == '1'"></admin-people>
            </v-container>
          </v-main>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import NavBar from "./NavBar.vue";
import ChartsPage from "./ChartsPage.vue";
import AdminPeople from "./AdminPeople.vue";
import AppBar from "../components/AppBar.vue";
export default {
  name: "MainPage",
  components: { NavBar, ChartsPage, AdminPeople, AppBar },
  mounted() {
    //  console.log(this.selectedPage);
  },
  data: function () {
    return { selectedPage: 0 };
  },
  methods: {
    onClickChild(value) {
      // console.log(value); // someValue
      this.selectedPage = value;
    },
  },
};
</script>

