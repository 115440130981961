<template>
  <div>
    <v-sheet v-if="!flippingCards[0].flipped" style="border-radius: 20px;">
      <v-card-title style="height: 12vh;"><v-spacer></v-spacer>
        <template>
          <div>
            <p style="font-size:1.1rem;">
              <b class="font-weight-bold text-uppercase" v-t="'charts-page[\'weight\']'">
              </b>
            </p>
          </div>
          <!--<v-avatar><v-icon @click="flippingCards[0].flipped = true">mdi-information</v-icon></v-avatar>-->
        </template>
        <v-spacer></v-spacer></v-card-title>
      <v-divider thickness="10px"></v-divider>

      <v-sheet style="overflow-y: scroll">
        <v-card-text>
          <v-progress-circular v-if="loadingTargetBinGraph ||
            loadingDayWeekBinGraph ||
            loadingSourceBinGraph
            " indeterminate color="primary"></v-progress-circular><br />
          <!--
            <template
              ><b
                class="mb-12 font-weight-bold"
                v-t="'charts-page[\'weight-history\']'"
              >
              </b
              ><br />
            </template>
            <v-row
              v-show="
                (lineChartBinConfig.options &&
                WeightDataSourceBin.length > 0 &&
                !loadingSourceBinGraph) || updatingSourceBinGraph
              "
            >
              <v-col class="d-flex justify-center align-center" cols="1">
                <v-btn
                  icon
                  @click="addDayAndUpdate(true)"
                  class="d-flex justify-center align-center"
                  ><v-icon size="45">mdi-menu-left</v-icon></v-btn
                ></v-col
              >
              <v-col cols="10">
                <apexchart
                  height="280px"
                  style="height: 200px"
                  type="line"
                  :options="lineChartBinConfig.options"
                  :series="lineChartBinConfig.series"
                ></apexchart>
                 <v-progress-linear
                  indeterminate
                  size="55"
                  v-if="updating"
                  class="mx-auto"
                  style="width: 50%"
                ></v-progress-linear
              >
                <v-alert
                  v-if="
                    WeightDataSourceBin.length == 0 && !loadingSourceBinGraph && !updatingSourceBinGraph
                  "
                  dense
                  outlined
                  dismissible
                  type="error"
                  class="mx-auto"
                  max-width="250px"
                >
                  <span v-t="'no-results-generic'"></span>
                </v-alert>
              </v-col>

              <v-col class="d-flex justify-center align-center" cols="1">
                <v-btn
                  icon
                  @click="addDayAndUpdate"
                  class="d-flex justify-center align-center"
                  ><v-icon size="45">mdi-menu-right</v-icon></v-btn
                ></v-col
              >
            </v-row>-->

          <v-card>
            <v-card-title>
              <template class="mb-2">
                <p style="font-size: 0.8rem; width: 100%;" class="mb-2  font-weight-bold text-uppercase"
                  v-t="'charts-page[\'weight-history-target\']'">
                </p>
              </template>
            </v-card-title>
            <v-card-text class="pl-2 pr-2">
              <apexchart v-show="lineChartBinTargetConfig.options &&
                WeightDataTargetBin.length > 0 &&
                !loadingTargetBinGraph
                " type="bar" :options="lineChartBinTargetConfig.options" :series="lineChartBinTargetConfig.series">
              </apexchart>
              <v-alert v-if="WeightDataTargetBin.length == 0 && !loadingTargetBinGraph" dense outlined dismissible
                type="error" class="mx-auto" max-width="250px">
                <span v-t="'no-results-generic'"></span>
              </v-alert>
            </v-card-text>
          </v-card>
          <div style="height: 5vh;">

          </div>
          <v-card>
            <v-card-title>
              <template class="mb-2">
                <p style="font-size: 0.8rem; width: 100%;" class="mb-2  font-weight-bold text-uppercase"
                  v-t="'charts-page[\'weight-history-recyclables\']'">
                </p>
              </template>
            </v-card-title>
            <v-card-text class="pl-2 pr-2">
              <apexchart v-show="lineChartBinRecyclablesConfig.options &&
                WeightDataRecyclables.length > 0 &&
                !loadingRecyclablesBinGraph
                " type="bar" :options="lineChartBinRecyclablesConfig.options" :series="lineChartBinRecyclablesConfig.series">
              </apexchart>
              <v-alert v-if="WeightDataRecyclables.length == 0 && !loadingRecyclablesBinGraph" dense outlined dismissible
                type="error" class="mx-auto" max-width="250px">
                <span v-t="'no-results-generic'"></span>
              </v-alert>
            </v-card-text>
          </v-card>
          <div style="height: 5vh;">

          </div>
          <v-card>
            <v-card-title>
              <template>
                <p style="font-size: 0.8rem; width: 100%;" class="mb-2 font-weight-bold text-uppercase"
                  v-t="'charts-page[\'weight-history-dayweek\']'">
                </p>
              </template>
            </v-card-title>
            <v-card-text class="pl-2 pr-2">
              <apexchart class="pl-2" v-show="lineChartBinDayWeekConfig.options &&
                WeightDataDayWeek.length > 0 &&
                !loadingDayWeekBinGraph
                " type="bar" :options="lineChartBinDayWeekConfig.options" :series="lineChartBinDayWeekConfig.series">
              </apexchart>
              <v-alert v-if="WeightDataDayWeek.length == 0 && !loadingDayWeekBinGraph" dense outlined dismissible
                type="error" class="mx-auto" max-width="250px">
                <span v-t="'no-results-generic'"></span>
              </v-alert>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-sheet>
    </v-sheet>

    <!--<transition name="flip">
      <v-sheet style="height: 100%" v-if="flippingCards[0].flipped">
        <v-card-title>
          <v-spacer></v-spacer>

          <template>
            <b v-t="'charts-page[\'weight-history\']'"></b>
            <v-avatar>
              <v-btn icon @click="flippingCards[0].flipped = false">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn></v-avatar>
          </template>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <p v-t="'charts-page[\'weight-history-desc\']'"></p>
        </v-card-text>
      </v-sheet>
    </transition>-->
  </div>
</template>


<script>
import { getWeights } from "../../utils/stations_api_client";

export default {
  props: ["selectedStation"],
  async beforeMount() {
    this.refreshChart();
  },
  data() {
    return {
      WeightDataSourceBin: [],
      WeightDataTargetBin: [],
      WeightDataDayWeek: [],
      WeightDataRecyclables: [],

      lineChartBinConfig: {},
      lineChartBinTargetConfig: {},
      lineChartBinDayWeekConfig: {},
      lineChartBinRecyclablesConfig: {},

      flippingCards: [{ name: "history", flipped: false }],
      activePicker: false,
      loadingSourceBinGraph: false,
      updatingSourceBinGraph: false,
      loadingTargetBinGraph: true,
      loadingDayWeekBinGraph: true,
      loadingRecyclablesBinGraph: true,
      graphScrollingCounter: 0,
      graphScrollingUpdateThreshold: 5,
      initialScrollingPos: null,
      currentScrollingPos: null,
      dateArr: [],
    };
  },
  watch: {
    selectedStation: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler() {
        // do your stuff
        //console.log(val);
        this.refreshChart();
      },
    },
    $graphsDates: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler() {
        // do your stuff
        this.refreshChart();
      },
    },
  },

  methods: {
    refreshChart() {
      this.loadingSourceBinGraph = false;
      this.loadingTargetBinGraph = true;
      this.loadingDayWeekBinGraph = true;
      this.loadingRecyclablesBinGraph = true,
      //this.selectedBin = "station";

      this.activePicker = false;
      //console.log(this.$selectedStation);
      /*  this.getStationWeightSumTimeFramed(this.$selectedStation).then(() => {
          this.loadingSourceBinGraph = false;
        });*/
      this.getStationWeightSumTimeFramedTargetBin(this.$selectedStation).then(
        () => {
          this.loadingTargetBinGraph = false;
        }
      );

      this.getStationWeightSumTimeFramedRecyclablesBin(this.$selectedStation).then(
        () => {
          this.loadingRecyclablesBinGraph = false;
        }
      );

      this.getStationWeightSumTimeFramedDayWeekBin(this.$selectedStation).then(
        () => {
          this.loadingDayWeekBinGraph = false;
        }
      );
    },
    async getStationWeightSumTimeFramed(stationRec) {
      this.WeightDataSourceBin = [];

      const apiResponseData = await getWeights(
        stationRec["StationId"],
        this.$graphsDates[0],
        this.$graphsDates[1]
      );

      if (stationRec["StationId"] === this.selectedStation.StationId) {
        this.WeightDataSourceBin = apiResponseData;
        this.plotDataRecRateBins(this.WeightDataSourceBin, 3);
      }
    },

    async getStationWeightSumTimeFramedUpdateSeries(stationRec, dates) {
      if (dates[0] != undefined && dates[1] != undefined) {
        this.WeightDataSourceBin = [];
        this.updatingSourceBinGraph = true;

        const apiResponseData = await getWeights(
          stationRec["StationId"],
          this.$graphsDates[0],
          this.$graphsDates[1]
        );

        if (stationRec["StationId"] === this.selectedStation.StationId) {
          this.WeightDataSourceBin = apiResponseData;
          this.plotDataRecRateBins(this.WeightDataSourceBin, 3);
          this.updatingSourceBinGraph = false;
        }
      }
    },

    async getStationWeightSumTimeFramedTargetBin(stationRec) {
      if (this.$graphsDates[0] != undefined && this.$graphsDates[1] != undefined) {
        this.WeightDataTargetBin = [];

        const apiResponseData = await getWeights(
          stationRec["StationId"],
          this.$graphsDates[0],
          this.$graphsDates[1],
          'source'
        );

        if (stationRec["StationId"] === this.selectedStation.StationId) {
          this.WeightDataTargetBin = apiResponseData;
          this.plotDataRecRateBins(this.WeightDataTargetBin, 1);
        }
      }
    },

    async getStationWeightSumTimeFramedRecyclablesBin(stationRec) {
      if (this.$graphsDates[0] != undefined && this.$graphsDates[1] != undefined) {
        this.WeightDataRecyclables = [];

        const apiResponseData = await getWeights(
          stationRec["StationId"],
          this.$graphsDates[0],
          this.$graphsDates[1],
          'recyclables'
        );

        if (stationRec["StationId"] === this.selectedStation.StationId) {
          this.WeightDataRecyclables = apiResponseData;
          this.plotDataRecRateBins(this.WeightDataRecyclables, 4);
        }
      }
    },

    async getStationWeightSumTimeFramedDayWeekBin(stationRec) {
      if (this.$graphsDates[0] != undefined && this.$graphsDates[1] != undefined) {
        this.WeightDataTargetBin = [];

        const apiResponseData = await getWeights(
          stationRec["StationId"],
          this.$graphsDates[0],
          this.$graphsDates[1],
          'weekday'
        );

        if (stationRec["StationId"] === this.selectedStation.StationId) {
          this.WeightDataDayWeek = apiResponseData;
          this.plotDataRecRateBins(this.WeightDataDayWeek, 2);
        }
      }
    },
    filterGraph(target, type) {
      // this.selectedBin = target; // Frontend refresh
      // Useful when user wants to filter entire data set by material (plastic, paper, etc.)
      //console.log(target);
      var filtered;
      if (type == 0) {
        filtered = this.WeightDataSourceBin.filter(
          (value) => value.material === target
        );
      } else if (type == 1) {
        filtered = this.WeightDataTargetBin.filter(
          (value) => value.material === target
        );
      } else if (type == 2) {
        filtered = this.WeightDataDayWeek.filter(
          (value) => value.material === target
        );
      }
      this.plotDataRecRateBins(filtered, type);
    },
    async addDayAndUpdate(back = false) {
      if (this.dateArr.length == 0) {
        this.dateArr = this.$graphsDates;
      }
      var dateStart = new Date(Date.parse(this.dateArr[0]));
      var dateEnd = new Date(Date.parse(this.dateArr[1]));
      dateEnd.setDate(dateEnd.getDate() + (back === true ? -1 : 1));
      dateStart.setDate(dateStart.getDate() + (back === true ? -1 : 1));
      this.dateArr = [];
      this.dateArr.push(
        dateStart.toISOString().substr(0, 10),
        dateEnd.toISOString().substr(0, 10)
      );
      this.graphScrollingCounter = 0;
      this.currentScrollingPos = null;
      this.initialScrollingPos = null;
      await this.getStationWeightSumTimeFramedUpdateSeries(
        this.$selectedStation,
        this.dateArr
      );
    },
    plotDataRecRateBins(data, type) {
      // Use this method to prepare data for plots.

      var categories = [];
      var datasets = [];
      const currentLanguage = this.$i18n.locale;
      var yAxisTxt = this.$t("charts-page.weight-analysis-y-axis");

      var dataset = {
        name: "Kg",
        data: [],
      };
      //console.log(ds);
      if (type == 0) {
        data.forEach((element, i) => {
          var key = "charts-page." + element.source_bin;
          var material_name = currentLanguage == "it-IT" ? element.source_bin_ita : element.source_bin
          if (i % 1 === 0) {
            categories.push(material_name);

            dataset.data.push(element.weight);
          }
        });
        datasets.push(dataset);
      } else if (type == 1) {
        data.forEach((element, i) => {
          var material_name = currentLanguage == "it-IT" ? element.source_bin_ita : element.source_bin
          if (i % 1 === 0) {
            categories.push(material_name);

            dataset.data.push(element.weight);
          }
        });
        datasets.push(dataset);
      } else if (type == 2) {
        data.forEach((element, i) => {
          var key = "charts-page." + element.day_name;
          if (i % 1 === 0) {
            categories.push(this.$t(key));

            dataset.data.push(element.weight);
          }
        });
        datasets.push(dataset);
      } else if (type == 3) {
        categories = [];
        var ds = data.filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.material === value.material)
        );
        //console.log(ds);

        var labels = data.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.ref_date === value.ref_date)
        );
        // console.log(labels);

        // console.log(ds);
        labels.forEach((element, i) => {
          if (i % 1 === 0) {
            categories.push(element.ref_date);
          }
        });

        datasets = [];
        ds.forEach((item, i) => {
          if (i % 1 === 0) {
            var material_name = currentLanguage == "it-IT" ? item.material_ita : item.material_eng
            var dataset = {
              name: material_name,
              data: [],
            };
            labels.forEach((element) => {
              var record = data.filter(
                (value) =>
                  value.material === item.material &&
                  value.ref_date === element.ref_date
              );

              //console.log(record);
              if (record.length > 0) {
                dataset.data.push(record[0].weight);
              } else {
                dataset.data.push(0);
              }
            });
            datasets.push(dataset);
          }
        });
      }else if (type == 4) {
        data.forEach((element, i) => {
          var key = "charts-page." + element.target_bin
          if (i % 1 === 0) {
            categories.push(this.$t(key));

            dataset.data.push(element.weight);
          }
        });
        datasets.push(dataset);
      }

      /* REMEMBER: as shown in the guide, each time you have to update the graph,
       you have to redefine object from the outermost element. In this case: the root*/
      if (type == 0) {
        this.lineChartBinConfig = {
          options: {
            colors: ["#546E7A"],

            chart: {
              id: "Weights trend per bin",
              type: "bar",

              height: 350,
              zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: "zoom",
              },
            },
            yaxis: {
              title: {
                text: yAxisTxt,
              },
              labels: {
                formatter: function (val) {
                  return val.toFixed(2);
                },
              },
            },
            xaxis: {
              type: "category",
              categories: categories,
              dataLabels: {},
              tickAmount: 15,
              labels: {
                show: true,
                style: {
                  colors: "#000",
                  fontSize: "0.8rem",
                  fontFamily: "Codec Pro, sans-serif",
                },
              },
              axisBorder: { show: true },
              axisTicks: { show: true },
              tooltip: { enabled: true },
            },
            plotOptions: {
              bar: {
                borderRadius: 15,
                borderRadiusApplication: "around",
                borderRadiusWhenStacked: "last",
                columnWidth: "50%",
                barHeight: "50%",
                distributed: false,
                rangeBarOverlap: true,
                rangeBarGroupRows: false,
              },
            },

            dataLabels: { enabled: false },
            stroke: {
              curve: "smooth",
            },
          },
          series: datasets,
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        };
      } else if (type == 1) {
        this.lineChartBinTargetConfig = {
          options: {
            colors: ["#66DA26"],

            chart: {
              id: "Weights per target bin",
              type: "bar",
              height: 250,
              zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: "zoom",
              },
            },
            yaxis: {
              title: {
                text: yAxisTxt,
              },
              labels: {
                formatter: function (val) {
                  return val.toFixed(2);
                },
              },
            },
            xaxis: {
              type: "category",
              categories: categories,
              dataLabels: {},
              tickAmount: 15,
              labels: {
                show: true,
                style: {
                  colors: "#000",
                  fontSize: "0.8rem",
                  fontFamily: "Codec Pro, sans-serif",
                },
              },
              axisBorder: { show: true },
              axisTicks: { show: true },
              tooltip: { enabled: true },
            },
            plotOptions: {
              bar: {
                borderRadius: 15,
                borderRadiusApplication: "around",
                borderRadiusWhenStacked: "last",
                columnWidth: "40%",
                barHeight: "50%",
                distributed: false,
                rangeBarOverlap: true,
                rangeBarGroupRows: false,
              },
            },

            dataLabels: { enabled: false },
            stroke: {
              curve: "smooth",
            },
          },
          series: datasets,
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        };
      } else if (type == 2) {
        this.lineChartBinDayWeekConfig = {
          colors: ["#546E7A"],

          options: {
            chart: {
              id: "Weights analysis per day of the week",
              type: "bar",
              height: 250,
              zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: "zoom",
              },
            },
            yaxis: {
              title: {
                text: yAxisTxt,
              },
              labels: {
                formatter: function (val) {
                  return val.toFixed(2);
                },
              },
            },
            xaxis: {
              type: "category",
              categories: categories,
              dataLabels: {},
              tickAmount: 15,
              labels: {
                show: true,
                style: {
                  colors: "#000",
                  fontSize: "0.8rem",
                  fontFamily: "Codec Pro, sans-serif",
                },
              },
              axisBorder: { show: true },
              axisTicks: { show: true },
              tooltip: { enabled: true },
            },
            plotOptions: {
              bar: {
                borderRadius: 15,
                borderRadiusApplication: "around",
                borderRadiusWhenStacked: "last",
                columnWidth: "40%",
                barHeight: "50%",
                distributed: false,
                rangeBarOverlap: true,
                rangeBarGroupRows: false,
              },
            },

            dataLabels: { enabled: false },
            stroke: {
              curve: "smooth",
            },
          },
          series: datasets,
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        };
      } else if (type == 3) {
        this.lineChartBinConfig = {
          options: {
            chart: {
              events: {
                click: async function (event, chartContext, config) {
                  this.currentScrollingPos = event;
                  //console.log(
                  // this.initialScrollingPos.screenX,
                  //this.currentScrollingPos.screenX
                  //);
                  if (
                    this.graphScrollingCounter >=
                    this.graphScrollingUpdateThreshold &&
                    this.currentScrollingPos &&
                    this.initialScrollingPos
                  ) {
                    //console.log(chartContext, config.config.xaxis);
                    //console.log(this.$graphsDates);

                    var firstDate = chartContext.data.twoDSeriesX[0];
                    var latestDate =
                      chartContext.data.twoDSeriesX[
                      chartContext.data.twoDSeriesX.length - 1
                      ];
                    //console.log(
                    //  firstDate,
                    //  latestDate,
                    //  config.config.xaxis.max,
                    //  config.config.xaxis.min
                    //);

                    if (
                      config.config.xaxis.max >= latestDate &&
                      this.currentScrollingPos.screenX <
                      this.initialScrollingPos.screenX
                    ) {
                      this.addDayAndUpdate();
                    }

                    if (
                      config.config.xaxis.min <= firstDate &&
                      this.currentScrollingPos.screenX >
                      this.initialScrollingPos.screenX
                    ) {
                      this.addDayAndUpdate(true);
                    }
                  }
                }.bind(this),
                mouseMove: function (event) {
                  if (this.graphScrollingCounter == 0) {
                    this.initialScrollingPos = event;
                  }
                }.bind(this),
                scrolled: function () {
                  this.graphScrollingCounter++;
                }.bind(this),
                id: "Filling level per period",
                type: "line",
                stacked: false,
                height: 350,
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
            },
            yaxis: {
              tickAmount: 5,

              min: 0,
              labels: {
                formatter: function (val) {
                  return val.toFixed(2) + " kg";
                },
              },
              title: {
                text: "Weight sum",
              },
            },
            xaxis: {
              type: "datetime",
              categories: categories,
              dataLabels: {},
              tickAmount: 15,
              labels: {
                show: true,
                style: {
                  colors: "#000",
                  fontSize: "15px",
                  fontFamily: "Codec Pro, sans-serif",
                },
                format: "dd/MM",
              },
              axisBorder: { show: true },
              axisTicks: { show: true },
              tooltip: { enabled: true },
            },
            dataLabels: { enabled: false },
            stroke: {
              curve: "smooth",
            },
          },
          series: datasets,
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        };
      } else if (type == 4){
        this.lineChartBinRecyclablesConfig = {
          options: {
            colors: ["#66DA26"],

            chart: {
              id: "Weights per target bin",
              type: "bar",
              height: 250,
              zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: "zoom",
              },
            },
            yaxis: {
              title: {
                text: yAxisTxt,
              },
              labels: {
                formatter: function (val) {
                  return val.toFixed(2);
                },
              },
            },
            xaxis: {
              type: "category",
              categories: categories,
              dataLabels: {},
              tickAmount: 15,
              labels: {
                show: true,
                style: {
                  colors: "#000",
                  fontSize: "0.8rem",
                  fontFamily: "Codec Pro, sans-serif",
                },
              },
              axisBorder: { show: true },
              axisTicks: { show: true },
              tooltip: { enabled: true },
            },
            plotOptions: {
              bar: {
                borderRadius: 15,
                borderRadiusApplication: "around",
                borderRadiusWhenStacked: "last",
                columnWidth: "40%",
                barHeight: "50%",
                distributed: false,
                rangeBarOverlap: true,
                rangeBarGroupRows: false,
              },
            },

            dataLabels: { enabled: false },
            stroke: {
              curve: "smooth",
            },
          },
          series: datasets,
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        };
      }
      //console.log (this.lineChartBinConfig)
    },
    getCurrWeekMonSun() {
      // When displaying date range
      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      var last = first + 6; // last day is the first day + 6

      var firstday = new Date(curr.setDate(first)).toISOString().substr(0, 10);
      var lastday = new Date(curr.setDate(last)).toISOString().substr(0, 10);
      var arr = [];
      arr.push(firstday);
      arr.push(lastday);
      return arr;
    },
  },
  computed: {},
};
</script>
                        
<style></style>
                        
