
 /* eslint-disable */ 
<template>
  <div style="background-color: transparent">
    <v-row>
      <v-col xl="12" lg="12" sm="12" cols="12">
        <v-card v-if="$selectedStation != '' && $selectedStation != null" color="transparent" flat>
          <v-row class="mt-5">
            <v-col lg="8" sm="12" cols="12" style="height: 100%; padding-bottom: 0px;">
              <v-card class="mx-auto text-center rounded-xl" style="height: 100%; padding-bottom: 0px;">
                <!--<v-sheet v-if="isMobileDevice" class="transition-fast-in-fast-out v-card--reveal">
                  <v-card-title><v-spacer></v-spacer><template>
                      <b class="cardtitle text-md-center mx-center" color=" ">{{
                        $selectedBuilding["BuildingName"]
                      }}</b> </template>

                    <v-spacer></v-spacer></v-card-title>
                  <v-divider thickness="10px"></v-divider>

                  <v-card-text class="
                      text-h6
                      font-weight-bold
                      text-center
                      nandocolor--text
                    "></v-card-text>
                                      <v-row><v-col></v-col></v-row>
                                    </v-sheet>-->

                <v-row>
                  <v-col xl="8" lg="8" md="8" sm="12" xa="8" class="pt-0 pb-0 fill-height">
                    <template>
                      <div>
                        <v-expansion-panels elevation="4" class="mapboxgl-ctrl selector rounded-x1" v-model="openedPanels"
                          accordion>
                          <v-expansion-panel class="white-transparent">
                            <v-expansion-panel-header style="text-align: center; padding-top: 6px;
                                                  padding-bottom: 6px;">
                              <template v-slot:actions>
                                <v-icon size="25" class="mdi-menu-down">$expand</v-icon>
                              </template>
                              <v-row>
                                <v-list-item>
                                  <v-avatar tile class="rounded grey darken-3 mr-4" size="40">
                                    <v-icon size="22" color="white" class="rounded">mdi-office-building-outline
                                    </v-icon>
                                  </v-avatar>
                                  <v-list-item-content class="mx-auto">
                                    <v-list-item-title v-if="$selectedCustomer" style="font-size: 0.95rem;" class="
                                                            text-lg-left
                                                            font-weight-bold
                                                            text-uppercase                                        
                                                          ">{{
                                                            $selectedCustomer["name"] + "\n"
                                                          }} </v-list-item-title><span class="text-lg-left nandocolor--text"
                                      style="font-size: 0.8rem;">
                                      {{
                                        $selectedBuilding["BuildingName"]
                                      }}</span></v-list-item-content>
                                </v-list-item></v-row>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                              <v-spacer></v-spacer>
                              <v-flex align-self-end style="max-height: 25vh; overflow-y: auto; overflow-x: hidden;">
                                <building-selector :buildingsStations="$buildingsStations
                                      ? $buildingsStations.filter(
                                        (bs) =>
                                          bs.CustomerId ===
                                          $selectedCustomer.id
                                      )
                                      : ''
                                    "></building-selector></v-flex>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </div>
                      <div class="map-test mainpage_high_table pt-0">
                        <MglMap :repaint="true" ref="map" container="map-test" :center="mapOptions.center"
                          :accessToken="mapOptions.accessToken" :mapStyle="mapOptions.mapStyle" @load="zoomIn"
                          @change="zoomIn" :zoom="mapOptions.zoom" :minZoom="mapOptions.minZoom"
                          :maxZoom="mapOptions.maxZoom">
                          <MglMarker :coordinates.sync="mapOptions.markerCoords" color="green" />
                        </MglMap>
                      </div>
                    </template>
                  </v-col>
                  <v-col><station-selector></station-selector></v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col xl="4" lg="4" md="12" sm="12" cols="12" class="pt-0 fill-height">
              <v-card id="real_rr_station_card"
                class="mx-auto text-center rounded-xl pt-0 fill-height mainpage_high_table">
                <v-sheet class="pt-0" style="height: 100%;" color="transparent">
                  <v-card-title class="mb-0 pb-0"><v-spacer></v-spacer>
                    <template>
                      <b class="
                                                cardtitle
                                                text-md-center
                                                mx-center
                                                mb-0
                                                pb-0
                                                font-weight-bold
                                                text-uppercase
                          " v-t="'charts-page[\'rec-efficiency-rate-daily\']'"></b>
                    </template><!--<v-avatar class="justify-end"
                        ><v-icon @click="flippingCards[0].flipped = true"
                          >mdi-information</v-icon
                                            ></v-avatar
                                          >--><v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text style="height: 100%;">
                    <v-row v-show="loaders.globalRecRate" style="height: 100%;">
                      <v-card-text class="d-flex justify-center">
                        <apexchart type="radialBar" class="mt-0 pt-0 mb-0 pb-0" style="" width="100%" height="100%"
                          :options="radialChartRecyclingRateConfig.chartOpt" :series="[peopleScore.toFixed(0)]">
                        </apexchart>
                      </v-card-text>
                    </v-row>
                    <v-row v-show="!loaders.globalRecRate">
                      <v-col>
                        <v-progress-circular indeterminate class="mx-auto text-center" color="primary" height="auto"
                          style="top: 0vh"></v-progress-circular>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-sheet>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col style="padding-bottom: 5px; width: 100%;" xl="8" lg="8" md="8" sm="12" xs="12">
                  <template>
                    <v-tabs :show-arrows="false" background-color="transparent" color="white"
                      style="max-width: 90vw; overflow-x: auto;">
                      <v-tab background-color="white" class="mb-1" style="box-shadow: 1px 2px 5px #888888" dark :class="i.selected
                        ? 'nandocolor mx-1 rounded-lg'
                        : 'mx-1 rounded-lg white'
                        " @click="selectGraphType(ix)" v-for="(i, ix) in graphTypes" v-bind:key="ix">
                        <v-icon light class="mx-2">{{ i.icon }}
                        </v-icon><b v-if="i.selected" class="dark">{{
                          " " + $t(i.text_key)
                        }}</b>
                      </v-tab>
                    </v-tabs>
                  </template>
                </v-col>
                <v-col xl="4" lg="4" md="4" sm="12" xs="12" style="padding-bottom: 0px">
                  <v-text-field :label="$t('charts-page.select-dates')" solo class="date-txt-field"
                    v-model="renderedDates" prepend-icon="mdi-calendar" readonly @click="activePicker = true">
                  </v-text-field>
                  <v-dialog max-width="300px" persistent v-model="activePicker">
                    <v-date-picker :first-day-of-week="1" :locale="this.$i18n.locale" class="white" v-model="tempDates"
                      range elevation="4" min="2020-01-01">
                      <v-btn class="mx-auto" @click="
                        $graphsDates = tempDates;
                      activePicker = false;
                      selectedBin = 'station';
                      ">Ok</v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <!-- <v-col><v-btn>Last week</v-btn></v-col
                                          ><v-col><v-btn>Last month</v-btn></v-col>-->
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto text-center rounded-xl mb-6" style="min-height: 60vh; overflow: hidden;">
                <!--<v-row>
                  <v-col lg="8"></v-col>
                  <v-col lg="4" style="padding-bottom: 0px" cols="12">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field :label="$t('charts-page.select-dates')" outlined class="date-txt-field"
                          v-model="renderedDates" prepend-icon="mdi-calendar" readonly
                          @click="activePicker = true"></v-text-field></v-col>
                      </v-row>
                    <v-dialog max-width="300px" persistent v-model="activePicker">
                      <v-date-picker v-model="tempDates" range elevation="4" min="1950-01-01">
                        <v-btn class="mx-auto" @click="
  $graphsDates = tempDates;
activePicker = false;
selectedBin = 'station';
                        ">Ok</v-btn>
                      </v-date-picker></v-dialog>
                                      </v-col>
                                    </v-row>-->
                <v-row ref="graphsrow" style="overflow-x: auto;">
                  <v-col class="pb-6" :lg="graphTypes[2].selected || graphTypes[1].selected || graphTypes[3].selected ? 12 : 8
                    " :md="graphTypes[2].selected || graphTypes[3].selected || graphTypes[1].selected ? 12 : 8
    " cols="12">
                    <recycling-rate-per-bin-chart v-show="graphTypes[0].selected" :selectedBin="selectedBin"
                      :selectedStation="$selectedStation"></recycling-rate-per-bin-chart>
                    <recycling-percentage-per-bin-chart v-show="graphTypes[1].selected" :selectedBin="selectedBin"
                      :selectedStation="$selectedStation"></recycling-percentage-per-bin-chart>
                    <weight-per-bin-chart v-show="graphTypes[2].selected"
                      :selectedStation="$selectedStation"></weight-per-bin-chart>
                    <station-object-category-ranking v-show="graphTypes[3].selected"
                      :selectedStation="$selectedStation"></station-object-category-ranking>
                    <filling-level-analysis v-show="graphTypes[4].selected"
                      :selectedStation="$selectedStation"></filling-level-analysis>
                  </v-col>
                  <v-divider v-if="graphTypes[0].selected || graphTypes[4].selected" vertical thickness="30px"></v-divider>
                  <v-col v-if="graphTypes[0].selected || graphTypes[4].selected" cols="12" lg="4" md="4">
                    <v-row>
                      <v-col cols="12">
                        <v-card-title style="height: 14vh;">
                          <v-spacer>
                          </v-spacer>
                          <template>
                            <div style="width: 100%;">
                              <p style="font-size:1.1rem; display: inline; word-break: break-word;" class="
                                                    text-center
                                                    mx-auto
                                                    font-weight-bold                             
                                                  ">
                                <b v-if="graphTypes[0].selected" v-t="'charts-page[\'live-rec-efficiency-bin\']'"></b>
                                <b v-if="graphTypes[4].selected" v-t="'charts-page[\'live-filling-level-bin\']'"></b>
                              </p>
                            </div>
                          </template>
                          <v-spacer></v-spacer>
                        </v-card-title>
                      </v-col>
                    </v-row>
                    <v-divider thickness="10px"></v-divider>
                    <v-row>
                      <v-col>

                      </v-col>
                    </v-row>
                    <v-row v-show="loaders.binCards"
                      style="overflow-y: auto; max-height: 60vh; width: 100%; margin: auto;">
                      <v-col style="" cols="6" sm="6" lg="6" md="6" v-for="(i, ix) in rankArray" v-bind:key="ix">
                        <v-card style="" class="card-btn fill-height" @click="filterGraph(i['material_eng']); getDeviceStatus(i.id_tiny);">
                          <apexchart v-if="graphTypes[0].selected" :height="120" type="radialBar" :options="radialChartRecyclingRateBinConfig.chartOpt
                            " :series="!graphTypes[4].selected
    ? [
      i.rec_rate
        ? (parseFloat(i.rec_rate) * 100).toFixed(0)
        : 0,
    ]
    : [
      i.fill_level !== null && !isNaN(i.fill_level)
        ? (
          parseFloat(i.fill_level) * 100
        ).toFixed(0)
        : 'N/A',
    ]
    "></apexchart>
                          <apexchart v-if="graphTypes[4].selected" :height="120" type="radialBar" :options="radialChartFillLevelBinConfig.chartOpt
                            " :series="!graphTypes[4].selected
    ? [
      i.rec_rate
        ? (parseFloat(i.rec_rate) * 100).toFixed(0)
        : (
          parseFloat(0.0)
        ).toFixed(0),
    ]
    : [
      i.fill_level !== null && !isNaN(i.fill_level)
        ? (
          parseFloat(i.fill_level) * 100
        ).toFixed(0)
        : (
          parseFloat(0.0)
        ).toFixed(0),
    ]
    "></apexchart>
                          <v-card-title class=""><span class="
                                                    text-caption text-center
                                                    mx-auto
                                                    font-weight-bold
                                                  " style="font-size: 0.8rem; white-space: break-spaces;">{{
                                                    i.material_display
                                                  }}</span></v-card-title>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row align-center>
                      <v-progress-circular indeterminate v-show="!loaders.binCards" class="mx-auto text-center"
                        color="primary" style="top: 100px"></v-progress-circular>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <v-row v-if="$selectedStation == null">
          <v-spacer></v-spacer>
          <v-col cols="12">
            <v-card elevation="4" style="color:white; height: 60vh; width: 100vw; background-color: rgb(92 230 120);">
              <v-card-title tag="p" v-t="'no-data-title'" class="text-center mx-auto" style="display: inline-table">
                No data
              </v-card-title>
              <v-card-text v-t="'no-data'" style="color:white;" class="text-center mx-auto">

              </v-card-text>
              <img src="@/assets/background-nodata.png" style="box-shadow: 0 0 8px 8px white inset;" />
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>

<script>
// DataPage.vue

import { Auth } from "aws-amplify";
import axios from "axios";
import { MglMap, MglMarker } from "vue-mapbox";
import BuildingSelector from "../components/BuildingSelector.vue";
import FillingLevelAnalysis from "../components/FillingLevelAnalysisGraphs/FillingLevelAnalysis.vue";
import StationObjectCategoryRanking from "../components/ObjectAnalysisGraphs/StationObjectCategoryRanking.vue";
import RecyclingPercentagePerBinChart from "../components/RecyclingPercentageGraphs/RecyclingPercentagePerBinChart.vue";
import RecyclingRatePerBinChart from "../components/RecyclingRateGraphs/RecyclingRatePerBinChart.vue";
import StationSelector from "../components/StationsSelector.vue";
import WeightPerBinChart from "../components/WeightAnalysisGraphs/WeightAnalysisChart.vue";
import { getDataRank, getRecyclingRate, getStatus } from "../utils/stations_api_client";

export default {
  name: "ChartsPage",
  components: {
    RecyclingRatePerBinChart,
    RecyclingPercentagePerBinChart,
    WeightPerBinChart,
    StationObjectCategoryRanking,
    FillingLevelAnalysis,
    MglMap,
    MglMarker,
    BuildingSelector,
    StationSelector,
  },
  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.$scrollTop();
  },
  async beforeMount() {
    console.log("beforemount stations", this.$selectedStation)
    if (this.$selectedStation && this.$selectedStation != '') {
      this.refreshCharts();
    }else{
      console.log("setting null")
      this.$selectedStation = null;
    }

    this.$root.$off("updatedSelectedStation");
    this.$root.$on("updatedSelectedStation", async () => {
      //  console.log(val);
      this.openedPanels = false;
      if (this.$graphsDates.length == 0) {
        this.$graphsDates = this.getCurrWeekMonSun();
        //console.log(this.$graphsDates);
      }
      this.tempDates = this.$graphsDates;
      await this.refreshCharts();
    });

    this.$root.$off("clickedNestedBuilding");
    this.$root.$on("clickedNestedBuilding", async () => {
      //  console.log(val);
      this.openedPanels = false;
    });
  },
  async mounted() { 
    console.log("Stations after", this.$selectedStation);
    if (this.$selectedStation == '') {
      this.$selectedStation = null;
    }
   },

  data() {
    return {
      openedPanels: false,
      initSelectedStation: null,
      flippingCards: [{ name: "recRate", flipped: false }],
      selectedStation: this.$selectedStation,
      peopleScore: 0,
      rankArray: [],
      stationStatus: [],
      tempDates: [], // need for date picker, otherwise will update other graphs around even if user pickes only one date
      activePicker: false,
      menu: true,
      changedMapLocation: false,

      selectedBin: "station",
      deviceStatus: [],
      chartsHeight: 250,

      loaders: { binCards: false, globalRecRate: false },
      mapOptions: {
        accessToken:
          "pk.eyJ1IjoiZmVkZWZlZGkiLCJhIjoiY2t4NWFhenJkMGRmcDJwbnpxeWs1aGtsMSJ9.tJbbDC8omgsFUVLuiQyGfw", // your access token. Needed if you using Mapbox maps
        mapStyle: "mapbox://styles/mapbox/dark-v10", // your map style

        //url: "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png",

        zoom: 14, // GOES from
        center: [0, 0],
        markerCoords: [0, 0],
        minZoom: 5,
        maxZoom: 18,
      },

      graphTypes: [
        {
          text_key: "charts-page.rec-efficiency-rate-history",
          selected: true,
          icon: "mdi-recycle",
        },
        {
          text_key: "charts-page.rec-rate",
          selected: false,
          icon: "mdi-water-sync",
        },
        { text_key: "charts-page.weight", selected: false, icon: "mdi-weight" },
        {
          text_key: "charts-page.object-ranking",
          selected: false,
          icon: "mdi-podium-gold",
        },

        {
          text_key: "charts-page.filling-level",
          selected: false,
          icon: "mdi-ruler",
        },
      ],

      //Apexcharts.js
      radialChartRecyclingRateConfig: {
        series: [this.peopleScore],
        chartOpt: {
          colors: [
            function ({ value, seriesIndex, w }) {
              seriesIndex;
              w;
              if (value < 10) {
                return '#F4592F'
              } else if (value >= 10 && value <= 50) {
                return '#FF8200'
              } else if (value > 50 && value <= 60) {
                return '#BDDB43'
              } else if (value > 60 && value <= 90) {
                return '#5CE678'
              } else if (value > 90) {
                return '#DEB4FF'
              }
            }
          ],
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0
            },
          },
          chart: {
            type: "radialBar",
            width: "100%",
            height: "100%",
            parentHeightOffset: 0,
          },
          plotOptions: {
            radialBar: {
              margin: 0,

              hollow: {
                size: "60%",
              },
              track: {
                background: "#fff",
                strokeWidth: "77%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.35,
                },
              },
              padding: {
                top: 0,
              },
              dataLabels: {
                textAnchor: "middle",
                show: true,
                style: {
                  fontSize: "10",
                  fontFamily: "Codec Pro, sans-serif",
                  fontWeight: "bold",
                },
                name: {
                  color: "#31a238",
                  offsetY: -25,
                  show: true,
                  fontSize: "1.2rem",
                  label: "%",
                },
                value: {
                  offsetY: 10,
                  color: "#31a238",
                  fontSize: "3.0rem",
                  fontWeight: "bold",
                  fontFamily: "Codec Pro, sans-serif",
                  show: true,
                  formatter: function (val) {
                    return val;
                  },
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
          legend: {
            show: false
          },
          sparkline: {
            enabled: true
          },
          labels: ["%"],
        },
      },

      radialChartRecyclingRateBinConfig: {
        chartOpt: {
          colors: [
            function ({ value, seriesIndex, w }) {
              seriesIndex;
              w;
              if (value < 10) {
                return '#F4592F'
              } else if (value >= 10 && value <= 50) {
                return '#FF8200'
              } else if (value > 50 && value <= 60) {
                return '#BDDB43'
              } else if (value > 60 && value <= 90) {
                return '#5CE678'
              } else if (value > 90) {
                return '#DEB4FF'
              }
            }
          ],
          chart: {
            height: 130,
            type: "radialBar",
          },
          labels: ["%"],

          grid: {
            show: false,

            padding: {
              top: -5,
              bottom: -10,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: 0,
              endAngle: 360,
              hollow: {
                margin: 0,
                size: "70%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24,
                },
              },

              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.35,
                },
              },
              fill: {
                colors: ["#31a238"],
              },
              stroke: {
                lineCap: "round",
              },

              dataLabels: {
                textAnchor: "middle",
                style: {
                  fontSize: "0.7rem",
                  fontFamily: "Codec Pro, sans-serif",
                  fontWeight: "bold",
                },

                show: true,
                name: {
                  color: "#31a238",
                  offsetY: -10,
                  show: true,
                  fontSize: 10,
                  label: "%",
                },
                value: {
                  offsetY: 0,
                  color: "#31a238",
                  fontSize: 25,
                  fontWeight: "bold",
                  show: true,
                  formatter: function (val) {
                    return val;
                  },
                },
              },
            },
          },
        },
      },

      radialChartRecyclingPercentageBinConfig: {
        chartOpt: {
          colors: [
            function ({ value, seriesIndex, w }) {
              seriesIndex;
              w;
              if (value < 10) {
                return '#F4592F'
              } else if (value >= 10 && value <= 50) {
                return '#FF8200'
              } else if (value > 50 && value <= 60) {
                return '#BDDB43'
              } else if (value > 60 && value <= 90) {
                return '#5CE678'
              } else if (value > 90) {
                return '#DEB4FF'
              }
            }
          ],
          chart: {
            height: 130,
            type: "radialBar",
          },
          labels: ["%"],

          grid: {
            show: false,

            padding: {
              top: -5,
              bottom: -10,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: 0,
              endAngle: 360,
              hollow: {
                margin: 0,
                size: "70%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24,
                },
              },

              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.35,
                },
              },
              fill: {
                colors: ["#31a238"],
              },
              stroke: {
                lineCap: "round",
              },

              dataLabels: {
                textAnchor: "middle",
                style: {
                  fontSize: "0.7rem",
                  fontFamily: "Codec Pro, sans-serif",
                  fontWeight: "bold",
                },

                show: true,
                name: {
                  color: "#31a238",
                  offsetY: -10,
                  show: true,
                  fontSize: 10,
                  label: "%",
                },
                value: {
                  offsetY: 0,
                  color: "#31a238",
                  fontSize: 25,
                  fontWeight: "bold",
                  show: true,
                  formatter: function (val) {
                    return val;
                  },
                },
              },
            },
          },
        },
      },

      radialChartFillLevelBinConfig: {
        chartOpt: {
          colors: [
            function ({ value, seriesIndex, w }) {
              seriesIndex;
              w;
              if (value < 10) {
                return '#DEB4FF'
              } else if (value >= 10 && value <= 50) {
                return '#5CE678'
              } else if (value > 50 && value <= 60) {
                return '#BDDB43'
              } else if (value > 60 && value <= 90) {
                return '#FF8200'
              } else if (value > 90) {
                return '#F4592F'
              }
            }
          ],
          chart: {
            height: 130,
            type: "radialBar",
          },
          labels: ["%"],

          grid: {
            show: false,

            padding: {
              top: -5,
              bottom: -10,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: 0,
              endAngle: 360,
              hollow: {
                margin: 0,
                size: "70%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24,
                },
              },

              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.35,
                },
              },
              fill: {
                colors: ["#31a238"],
              },
              stroke: {
                lineCap: "round",
              },

              dataLabels: {
                textAnchor: "middle",
                style: {
                  fontSize: "0.7rem",
                  fontFamily: "Codec Pro, sans-serif",
                  fontWeight: "bold",
                },

                show: true,
                name: {
                  color: "#31a238",
                  offsetY: -10,
                  show: true,
                  fontSize: 10,
                  label: "%",
                },
                value: {
                  offsetY: 0,
                  color: "#31a238",
                  fontSize: 25,
                  fontWeight: "bold",
                  show: true,
                  formatter: function (val) {
                    return val;
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      console.log("N:" + newVal + " O:" + oldVal);
      this.refreshCharts();
    },
  },
  methods: {
    selectGraphType(ix) {
      this.graphTypes.forEach((item, index) => {
        if (index != ix) item.selected = false;
        else item.selected = true;
      });
    },
    async getStationStatus(stationRec) {
      this.stationStatus = await getStatus(stationRec["StationId"]);
    },
    async getStationRecyclingRate(stationRec) {
      this.initSelectedStation = this.$selectedStation;
      const myData = await getDataRank(stationRec['StationId']);
      this.peopleScore = Number.isFinite(myData?.people_score) ? myData?.people_score * 100 : 0.0;
    },

    async getDeviceStatus(deviceId) {
      this.deviceStatus = [];
      // TODO: What are we going to do with this?
      var endpoint = this.$resource + "readstation_display_info";
      var data = {
        type: "read_device_status",
        data: { device_id: deviceId },
      };
      var token = await (await Auth.currentSession())
        .getIdToken()
        .getJwtToken();
      await axios
        .post(this.$apiUrl + endpoint, data, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          //console.log(response);
          // if (response.status != 200)
          //console.log(response);
          //console.log(JSON.parse(response["data"]["body"])[0]);          
          this.deviceStatus = JSON.parse(response["data"]["body"]);
          //console.log(this.deviceStatus);
        });
    },

    updateRankArray(apiResponseData) {
      console.log(this);
      this.rankArray = apiResponseData;
      const currentLanguage = this.$i18n.locale;
      for (var count = 0; count < this.rankArray.length; count++) {
        var key = "charts-page." + this.rankArray[count]["material"];
        var material_name = currentLanguage == "it-IT" ? this.rankArray[count]["material_ita"] : this.rankArray[count]["material_eng"]
        if (this.rankArray[count]["fill_level"] == "") {
          this.rankArray[count]["fill_level"] = 0;
        }
        this.rankArray[count]["material_display"] = material_name;
      }
    },

    async getBinsRecyclingRate(stationRec) {
      this.initSelectedStation = this.$selectedStation;
      const apiResponseData = await getRecyclingRate(stationRec["StationId"]);
      this.updateRankArray(apiResponseData);
    },

    async getBinsRecyclingPercentage(stationRec) {
      this.initSelectedStation = this.$selectedStation;
      const apiResponseData = await getRecyclingRate(stationRec["StationId"]);
      this.updateRankArray(apiResponseData);
    },

    filterGraph(selectedBin) {
      this.selectedBin = selectedBin;
    },
    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    },

    async refreshCharts() {
      this.loaders.globalRecRate = false;
      this.loaders.binCards = false;
      if (this.$selectedStation != null && this.$selectedStation != "") {
        //console.log("Enter map update");
        await this.getStationRecyclingRate(this.$selectedStation).then(() => {
          this.loaders.globalRecRate = true;
        });
        await this.getBinsRecyclingRate(this.$selectedStation).then(() => {
          this.loaders.binCards = true;
        });
        // await this.getStationRecyclingRateTimeFramed(this.$selectedStation);
        // The following line triggers a request and is not impacting on anything
        // await this.getStationStatus(this.$selectedStation);
        this.mapToSelectedStation();
      }
    },

    async mapToSelectedStation() {
      this.changedMapLocation = true;
      //console.log(this.$selectedStation);
      this.mapOptions.center = [];
      /*console.log(
        this.$selectedBuilding.latitude,
        this.$selectedBuilding.longitude
      );*/
      this.mapOptions.center.push(
        this.$selectedBuilding.longitude,

        this.$selectedBuilding.latitude
      );

      this.mapOptions.markerCoords = this.mapOptions.center;
      // console.log(this.$refs.map.Map);

      this.changedMapLocation = false;
    },

    async zoomIn(event) {
      // Here we cathing 'load' map event
      //   console.log(event);
      await event.component.actions.flyTo({
        center: this.mapOptions.center,
        zoom: this.mapOptions.zoom,
        speed: 1,
      });
    },

    onMapLoaded(event) {
      // in component
      // or just to store if you want have access from other components
      this.$store.map = event.map;
    },
    getCurrWeekMonSun() {
      // When displaying date range
      var first = new Date(); // get current date
      first.setMonth(first.getMonth() - 1); // First day is the day of the month - the day of the week
      //console.log(first.getDate())
      var last = new Date; // last day is the first day + 6
      //console.log(last.getDate())

      var firstday = first.toISOString().substr(0, 10);
      //console.log(firstday)
      var lastday = last.toISOString().substr(0, 10);
      //console.log(lastday)
      var arr = [];
      arr.push(firstday);
      arr.push(lastday);
      return arr;
    },
  },

  computed: {
    renderedDates() {
      return this.$graphsDates.length > 0
        ? this.$graphsDates[0] + " / " + this.$graphsDates[1]
        : "";
    },
    stationSwitch() {
      if (this.stationStatus) return this.stationStatus["status"] == "live";
      else return false;
    },

    ratio() {
      var ratio = 1;
      var isMobile = navigator.userAgentData.mobile; //resolves true/false

      if (!isMobile && screen.width > 600) ratio = 1.5;
      return ratio;
    },
  },
};
</script>
<style scoped>
.map-test {
  position: relative;
  text-align: left;
  border-radius: 20px !important;
}

.selector {
  margin-top: 15px;
  margin-left: 10%;
  position: absolute;
  width: 50%;
  max-width: 480px;
  /* opacity: 80% !important;*/
  white-space: pre-line;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 5;
  overflow: hidden;
  border-radius: 12px;
  word-wrap: break-word;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.mainpage_high_table {
  height: 40vh;
  max-height: 400px;
}

@media only screen and (max-width: 1263px) {
  #real_rr_station_card {
    margin-top: 5vh;
  }

  .selector {
    width: 80%;
  }
}


.footer {
  background-color: #e9f5e7;
}
</style>


