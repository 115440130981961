<template>
  <v-row class="fill-width">
    <v-col cols="12" v-for="(item, ix) in buildingsStations" v-bind:key="ix">
      <v-card flat @click="setSelectedBuilding(item)">
        <v-list-item class="mb-0 pb-0"><v-list-item-icon><v-icon size="20"
              right>mdi-office-building-outline</v-icon></v-list-item-icon>
          <v-list-item-content><span class="text-center" style="font-size: 0.8rem;"> {{
            item["BuildingName"]
          }}</span></v-list-item-content></v-list-item>
      </v-card></v-col>
  </v-row>
</template>

<script>
export default {
  props: ["buildingsStations"],

  data() {
    return {};
  },
  methods: {
    setSelectedBuilding(b) {
      this.$selectedBuilding = b;
      if (b.stations) this.$selectedStation = b.stations[0];

      //console.log(this.$selectedBuilding);
      this.$root.$emit("updatedSelectedStation", "hi");
      this.$root.$emit("clickedNestedStation", "hi");
      this.$root.$emit("updatedSelectedBuilding", "hi");
      this.$root.$emit("clickedNestedBuilding", "hi");
    },
  },
};
</script>

<style scoped>
.fill-width {
  flex-wrap: wrap;
}

.v-expansion-panel {
  min-width: 16rem;
  min-height: 20%;
  margin-right: 50px;
  margin-left: 50px;
}

.v-expansion-panel-content {
  min-width: 100%;
  min-height: 20%;
}
</style>