<template>
  <div>
    <app-bar-login></app-bar-login>

    <div class="container">
      <v-row>
        <v-spacer></v-spacer>

        <v-col cols="12" lg="5">
          <v-form>
            <v-toolbar-title>Reset password</v-toolbar-title>

            <v-text-field
              label="E-mail"
              v-model="email"
              type="text"
              autofocus
              prepend-icon="mdi-email"
            >
            </v-text-field>

            <v-row v-if="errorSendCode">
              <v-spacer></v-spacer>
              <v-alert
                v-if="errorSendCode"
                dense
                outlined
                dismissible
                type="error"
              >
                {{ errorSendCodeMessage }}
              </v-alert>
              <v-spacer></v-spacer>
            </v-row>

            <v-text-field
              v-if="codeSent"
              :label="$t('verification-code')"
              v-model="verificationCode"
              type="password"
              autofocus
              prepend-icon="mdi-timer-lock-outline"
            >
            </v-text-field>
            <v-text-field
              v-if="codeSent"
              :label="$t('new-password')"
              v-model="password"
              type="password"
              autofocus
              prepend-icon="mdi-lock"
            >
            </v-text-field>

            <v-row v-if="errorCheckCode">
              <v-spacer></v-spacer>
              <v-alert
                v-if="errorCheckCode"
                dense
                outlined
                dismissible
                type="error"
              >
                {{ errorCheckCodeMessage }}
              </v-alert>
              <v-spacer></v-spacer>
            </v-row>

            <v-row v-if="codeVerified">
              <v-spacer></v-spacer>
              <v-alert
                v-if="codeVerified"
                dense
                outlined
                dismissible
                type="success"
              >
                {{ $t("verification-code-success") }}
              </v-alert>
              <v-spacer></v-spacer>
            </v-row>
          </v-form>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-spacer></v-spacer><v-col cols="12" lg="5"></v-col>
        <v-spacer></v-spacer
      ></v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col lg="5" cols="12">
          <v-container class="">
            <v-row>
              <md-button
                v-if="!codeSent"
                class="nandocolor mx-auto"
                v-t="'resetPasswordPage[\'send-code\']'"
                @click.stop="sendCode"
              ></md-button>

              <md-button
                v-if="codeSent && !codeVerified"
                class="nandocolor mx-auto"
                v-t="'resetPasswordPage[\'verification-code-submit\']'"
                @click.stop="checkCode"
              ></md-button>
            </v-row>
            <v-row><v-col></v-col></v-row>
            <v-row
              ><md-button
                class="nandocolor mx-auto"
                v-t="Login"
                @click.stop="$router.push('/login')"
                >Login</md-button
              ></v-row
            >
            <v-row><v-col></v-col></v-row>

            <v-row></v-row>
            <v-row><v-col cols="12"></v-col></v-row>
            <v-row> </v-row>
          </v-container>
        </v-col>

        <v-spacer></v-spacer
      ></v-row>
    </div>
  </div>
</template>

<script>
import AppBarLogin from "../components/AppBarLogin.vue";
import { Auth } from "aws-amplify";
import axios from "axios";

export default {
  components: { AppBarLogin },
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      verificationCode: "",
      errorSendCode: false,
      errorSendCodeMessage: "",
      errorCheckCode: false,
      errorCheckCodeMessage: "",

      codeSent: false,
      codeVerified: false,
    };
  },
  methods: {
    async sendCode() {
      this.errorSendCode = false;

      try {
        await Auth.forgotPassword(this.email).then(async () => {
          //console.log(val);
          this.codeSent = true;
        });
      } catch (error) {
        this.errorSendCode = true;
        this.errorSendCodeMessage = error.message;
      }
    },

    async checkCode() {
      this.errorCheckCode = false;

      try {
        await Auth.forgotPasswordSubmit(
          this.email,
          this.verificationCode,
          this.password
        ).then(async () => {
          //console.log(val);
          this.codeVerified = true;
        });
      } catch (error) {
        this.errorCheckCode = true;
        this.errorCheckCodeMessage = error.message;
      }
    },

    async retrieveUserGroup() {
      var endpoint = this.$resource + "readwriteusers";
      var data = { type: "get_user_role" };
      var session = await Auth.currentSession();
      var token = await session.getIdToken().getJwtToken();
      var username = await session.getIdToken().payload.nickname;

      //console.log("inside retrieve");
      this.$userData = { username };
      await axios
        .post(this.$apiUrl + endpoint, data, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          //console.log(response);

          this.$userRole = response.data.body[0];
        });
    },

    signUp() {
      this.$router.push("/register");
    },
  },
};
</script>
