<template>
  <!-- eslint-disable -->
  <div>
    <v-sheet v-show="!flippingCards[0].flipped" style="border-radius: 20px;">
      <v-card-title style="height: 12vh;"><v-spacer></v-spacer>
        <template>
          <div>
            <p style="font-size:1.1rem;">
              <b class="font-weight-bold text-uppercase" v-t="'charts-page[\'object-ranking\']'">
              </b>
            </p>
          </div>
          <!--<v-avatar><v-icon @click="
  flippingCards[0].flipped = true;
$refs.donutSheet.style.transition = 'none';
          ">mdi-information</v-icon></v-avatar>-->
        </template>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider thickness="10px"></v-divider>

      <v-sheet style="overflow-y: auto">
        <v-card-text>
          <v-card>
            <v-card-title>
              <template>
                <p class="mb-2 font-weight-bold" style="font-size: 0.8rem; width: 100%;">
                  <b v-t="'charts-page[\'object-ranking-history\']'">
                  </b>
                </p>
              </template>
            </v-card-title>
            <v-card-text>
              <apexchart v-if="!isMobileDevice" id="donutSheet" height="300" v-show="chartDetails &&
                objectCategoryDetailsData.length > 0 &&
                !loadingObjectDetails
                " type="donut" :options="chartDetails" :series="chartDetails.series"></apexchart>
              <apexchart v-if="isMobileDevice" id="donutSheet" height="300" v-show="chartDetails &&
                objectCategoryDetailsData.length > 0 &&
                !loadingObjectDetails
                " type="donut" :options="chartDetailsMobile" :series="chartDetails.series"></apexchart>
              <v-alert v-if="objectCategoryDetailsData.length == 0 && !loadingObjectDetails
                " dense outlined dismissible type="error" class="mx-auto pt-1" max-width="250px">
                <span v-t="'no-results-generic'"></span>
              </v-alert>
            </v-card-text>

          </v-card>
          <div style="height: 5vh;"></div>
          <!--<v-card>
            <v-card-title>
              <template>
                <p class="mb-2 font-weight-bold" style="font-size: 0.8rem; width: 100%;">
                  <b v-t="'charts-page[\'object-error-details\']'">
                  </b>
                </p>
                <p class="mb-2 font-weight-bold" style="font-size: 0.8rem; width: 100%;">
                  <span class=" text-caption text-center
                                                    mx-auto
                                                    font-weight-bold
                                                  " style="font-size: 0.8rem; white-space: nowrap;"><b
                      v-t="'charts-page[\'object-error-details-subtitle\']'"></b></span>
                </p>
                <div class="mb-2" style="text-align: center; width: 100%;">
                  <div class="legend" style="font-size: 0.8rem; width: 100%; text-align: center;">
                    <span class="legend-item">
                      <span class="legend-color correct"></span>
                      <span class=" text-caption text-center
                                                    mx-auto                                                    
                                                  " style="font-size: 0.8rem; white-space: nowrap;"
                        v-t="'charts-page[\'object-error-details-legend_c\']'">
                      </span>
                    </span>
                    <span class="legend-item">
                      <span class="legend-color wrong"></span>
                      <span class=" text-caption text-center
                                                    mx-auto                                                    
                                                  " style="font-size: 0.8rem; white-space: nowrap;"
                        v-t="'charts-page[\'object-error-details-legend_w\']'">
                      </span>
                    </span>
                  </div>
                </div>
              </template>
            </v-card-title>
            <v-card-text>
              <v-col style="" cols="12" sm="12" lg="12" md="12" v-for="(i, ix) in binMaterialArray" v-bind:key="ix">
                <span class=" text-caption text-center
                                                    mx-auto
                                                    font-weight-bold
                                                  " style="font-size: 0.8rem; white-space: nowrap;">{{
                                                    i.material_name
                                                  }}</span>
                <apexchart v-if="!isMobileDevice" :id="`donutSheet_${i.key}`" height="300" v-show="chartErrorsDetails &&
                  objectErrorDetailsData[i.key].length > 0 &&
                  !loadingObjectErrorsDetails
                  " type="donut" :options="plotDataErrorsObjectRanking(objectErrorDetailsData[i.key], isMobileDevice)"
                  :series="plotDataErrorsObjectRanking(objectErrorDetailsData[i.key], isMobileDevice).series"></apexchart>
                <apexchart v-if="isMobileDevice" :id="`donutSheet_${i.key}`" height="300" v-show="chartErrorsDetails &&
                  objectErrorDetailsData[i.key].length > 0 &&
                  !loadingObjectErrorsDetails
                  " type="donut" :options="plotDataErrorsObjectRanking(objectErrorDetailsData[i.key], isMobileDevice)"
                  :series="plotDataErrorsObjectRanking(objectErrorDetailsData[i.key], isMobileDevice).series"></apexchart>
                <v-alert v-if="objectErrorDetailsData[i.key].length == 0 && !loadingObjectErrorsDetails
                  " dense outlined dismissible type="error" class="mx-auto pt-1" max-width="250px">
                  <span v-t="'no-results-generic'"></span>
                </v-alert>
              </v-col>
            </v-card-text>
          </v-card>
          <div style="height: 5vh;"></div>-->
          <v-card>
            <v-card-title>
              <template>
                <p class="mb-2 font-weight-bold" style="font-size: 0.8rem; width: 100%;">
                  <b v-t="'charts-page[\'object-ranking-history-hourly\']'"></b>
                </p>
              </template>
            </v-card-title>
            <v-card-text>
              <apexchart v-show="chartHourlyDistribution.options &&
                objectCategoryHourlyDistribution.length > 0 &&
                !loadingHourlyDistribution
                " type="bar" :options="chartHourlyDistribution.options" :series="chartHourlyDistribution.series">
              </apexchart>
              <v-row><v-col></v-col></v-row>

              <v-alert v-if="objectCategoryHourlyDistribution.length == 0 &&
                !loadingHourlyDistribution
                " dense outlined dismissible type="error" class="mx-auto pt-2" max-width="250px">
                <span v-t="'no-results-generic'"></span>
              </v-alert>
            </v-card-text>
          </v-card>
          <div style="height: 5vh;"></div>
          <v-card>
            <v-card-title>
              <template>
                <p class="mb-2 font-weight-bold" style="font-size: 0.8rem; width: 100%;">
                  <b v-t="'charts-page[\'object-ranking-history-dayweek\']'">
                  </b>
                </p>
              </template>
            </v-card-title>
            <v-card-text>
              <apexchart v-show="chartWeekDayDistribution.options &&
                objectCategoryWeekDayDistribution.length > 0 &&
                !loadingWeekDayDistribution
                " type="bar" :options="chartWeekDayDistribution.options" :series="chartWeekDayDistribution.series">
              </apexchart>

              <v-alert v-if="objectCategoryWeekDayDistribution.length == 0 &&
                !loadingWeekDayDistribution
                " dense outlined dismissible type="error" class="mx-auto" max-width="250px">
                <span v-t="'no-results-generic'"></span>
              </v-alert>
            </v-card-text>
          </v-card>
          <v-progress-circular v-if="loadingObjectDetails ||
            loadingHourlyDistribution ||
            loadingWeekDayDistribution
            " indeterminate color="primary"></v-progress-circular>
        </v-card-text>
      </v-sheet>
    </v-sheet>
    <!--<transition name="flip">
      <v-sheet id="" style="height: 100%" v-show="flippingCards[0].flipped">
        <v-card-title>
          <v-spacer></v-spacer>

          <template>
            <b v-t="'charts-page[\'object-ranking-history\']'"></b>
            <v-avatar>
              <v-btn icon @click="flippingCards[0].flipped = false">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn></v-avatar>
          </template>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <p v-t="'charts-page[\'object-ranking-history-desc\']'"></p>
        </v-card-text>
      </v-sheet>
    </transition>-->
  </div>
</template>


<script>
import { Auth } from "aws-amplify";
import axios from "axios";
import { getObjectCategoryRanking } from "../../utils/stations_api_client";

export default {
  props: ["selectedStation"],
  async beforeMount() {
    this.refreshCharts();
  },
  data() {
    return {
      binMaterialArray: [],

      objectCategoryDetailsData: [],
      objectCategoryHourlyDistribution: [],
      objectCategoryWeekDayDistribution: [],
      objectErrorDetailsData: [],

      chartDetails: {},
      chartErrorsDetails: {},
      chartHourlyDistribution: {},
      chartWeekDayDistribution: {},

      flippingCards: [{ name: "history", flipped: false }],

      loadingObjectDetails: true,
      loadingObjectErrorsDetails: true,
      loadingHourlyDistribution: true,
      loadingWeekDayDistribution: true,
    };
  },
  watch: {
    selectedStation: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler() {
        // do your stuff
        //console.log(val);
        this.refreshCharts();
      },
    },

    $graphsDates: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler() {
        // do your stuff
        this.refreshCharts();
      },
    },
  },

  methods: {
    refreshCharts() {
      this.loadingObjectDetails = true;
      this.loadingObjectErrorsDetails = true;
      this.loadingHourlyDistribution = true;
      this.loadingWeekDayDistribution = true;

      this.selectedBin = "station";

      this.getStationObjectDetailsTimeFramed(this.$selectedStation).then(() => {
        this.loadingObjectDetails = false;
      });

      /*this.getStationObjectErrorsTimeFramed(this.$selectedStation).then(() => {
        this.loadingObjectErrorsDetails = false;
      });*/

      this.getStationObjectHourlyTimeFramed(this.$selectedStation).then(() => {
        this.loadingHourlyDistribution = false;
      });

      this.getStationObjectWeekDayTimeFramed(this.$selectedStation).then(() => {
        this.loadingWeekDayDistribution = false;
      });
    },
    async getStationObjectDetailsTimeFramed(stationRec) {
      if (this.$graphsDates[0] != undefined && this.$graphsDates[1] != undefined) {
        this.objectCategoryDetailsData = [];

        const apiResponseData = await getObjectCategoryRanking(
          stationRec["StationId"],
          this.$graphsDates[0],
          this.$graphsDates[1]
        );

        this.objectCategoryDetailsData = apiResponseData;
        this.plotDataObjectRanking(this.objectCategoryDetailsData, 0);
      }
    },
    async getStationObjectErrorsTimeFramed(stationRec) {
      // TODO: Confirm what should we do with this.
      if (this.$graphsDates[0] != undefined && this.$graphsDates[1] != undefined) {
        this.objectErrorDetailsData = [];

        var endpoint = this.$resource + "readstation_display_info";
        var data = {
          type: "read_station_object_error_ranking",
          data: {
            station_id: stationRec["StationId"],
            start_date: "" + this.$graphsDates[0] + "",
            end_date: "" + this.$graphsDates[1] + "",
          },
        };

        //console.log(data);
        var token = (await Auth.currentSession()).getIdToken().getJwtToken();
        await axios
          .post(this.$apiUrl + endpoint, data, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            if (data["data"]["station_id"] ==  stationRec["StationId"]){
              if (response["data"]["body"]) {
                if (response["data"]["statusCode"] == 200) {
                  this.objectErrorDetailsData = JSON.parse(response["data"]["body"])["data"];
                  this.binMaterialArray = [];
                  for (var count = 0; count < JSON.parse(response["data"]["body"])["bins"].length; count++) {
                    var key = "charts-page." + JSON.parse(response["data"]["body"])["bins"][count];
                    var material_name = this.$t(key);
                    this.binMaterialArray.push({ "material_name": material_name, "key": JSON.parse(response["data"]["body"])["bins"][count] });
                  }

                  //this.plotDataObjectRanking(this.objectErrorDetailsData, 3);
                }
              }
            }
          });
      }
    },

    async getStationObjectHourlyTimeFramed(stationRec) {
      if (this.$graphsDates[0] != undefined && this.$graphsDates[1] != undefined) {
        const apiResponseData = await getObjectCategoryRanking(
          stationRec["StationId"],
          this.$graphsDates[0],
          this.$graphsDates[1]
        );

        this.objectCategoryDetailsData = apiResponseData;
        this.plotDataObjectRanking(this.objectCategoryDetailsData, 0);
      }

      if (this.$graphsDates[0] != undefined && this.$graphsDates[1] != undefined) {
        this.objectCategoryHourlyDistribution = [];

        const apiResponseData = await getObjectCategoryRanking(
          stationRec["StationId"],
          this.$graphsDates[0],
          this.$graphsDates[1],
          'hour'
        );

        this.objectCategoryHourlyDistribution = apiResponseData;

        var diffTime = this.getTimezoneOffset();

        //Fix the hours to the current timezone
        for (let count = 0; count < this.objectCategoryHourlyDistribution.length; count++) {
          if (diffTime[0] == "+") {
            this.objectCategoryHourlyDistribution[count].day_and_hour += diffTime[1];
          } else {
            this.objectCategoryHourlyDistribution[count].day_and_hour -= diffTime[1]
          }
        }
        this.objectCategoryHourlyDistribution

        this.plotDataObjectRanking(
          this.objectCategoryHourlyDistribution,
          1
        );
      }
    },

    getTimezoneOffset() {
      //function z(n){return (n<10? '0' : '') + n}
      var offset = new Date().getTimezoneOffset();
      var sign = offset < 0 ? '+' : '-';
      offset = Math.abs(offset);
      return [sign, (offset / 60 | 0)];
    },

    async getStationObjectWeekDayTimeFramed(stationRec) {
      //console.log(stationRec);
      //console.log(stationRec["StationId"]);

      //console.log(this.$graphsDates);
      if (this.$graphsDates[0] != undefined && this.$graphsDates[1] != undefined) {
        this.objectCategoryWeekDayDistribution = [];

        const apiResponseData = await getObjectCategoryRanking(
          stationRec["StationId"],
          this.$graphsDates[0],
          this.$graphsDates[1],
          'weekday'
        );

        this.objectCategoryWeekDayDistribution = apiResponseData;
        this.plotDataObjectRanking(
          this.objectCategoryWeekDayDistribution,
          2
        );
      }
    },

    plotDataErrorsObjectRanking(data, isMobile) {
      // Use this method to prepare data for plots.
      var categories = [];
      var datasets = [];

      var colorArr = [];

      data.forEach((element, i) => {
        if (i % 1 === 0) {
          colorArr.push(element.Correct === 1 ? '#0da602' : '#d65d06');

          var objectName = "";
          if (this.$i18n.locale == "it-IT" || this.$i18n.locale == "it" ) {
            objectName = element.ita_name;
          } else {
            objectName = element.eng_name;
          }

          /*var correctWrong = element.Correct*/

          categories.push(objectName);

          datasets.push(element.object_count);
        }
      });

      /* REMEMBER: as shown in the guide, each time you have to update the graph,
      you have to redefine object from the outermost element. In this case: the root*/

      if (!isMobile) { //Return chartOption no mobile

        var chartErrorsDetails = {
          // eslint-disable no-unused-vars
          theme: {
            mode: "light",
            palette: "palette1",
          },

          /*   */
          colors: colorArr,
          series: datasets,
          labels: categories,
          legend: {
            show: true,
            horizontalAlign: "right",
            position: "right",
            floating: true,
            itemMargin: {},
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              console.log(series)
              console.log(dataPointIndex)
              const dataLabel = w.config.labels[seriesIndex];
              return `<div class="custom-tooltip">${dataLabel}</div>`;
            },
          },
          chartOptions: {
            chart: {
              width: 350,
              type: "donut",
            },
            plotOptions: {
              pie: {
                startAngle: 0,
                endAngle: 360,
              },
            },
            dataLabels: {
              enabled: false,
            },

            fill: {},

            title: {
              text: "",
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 300,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
        };
        return chartErrorsDetails;
      } else if (isMobile) {
        var chartErrorsDetailsMobile = {
          // eslint-disable no-unused-vars
          theme: {
            mode: "light",
            palette: "palette1",
          },

          /*   */
          colors: colorArr,
          series: datasets,
          labels: categories,
          legend: {
            show: false,
            horizontalAlign: "right",
            position: "right",
            floating: true,
            itemMargin: {},
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              console.log(series)
              console.log(dataPointIndex)
              const dataLabel = w.config.labels[seriesIndex];
              return `<div class="custom-tooltip">${dataLabel}</div>`;
            },
          },
          chartOptions: {
            chart: {
              width: 350,
              type: "donut",
            },
            plotOptions: {
              pie: {
                startAngle: 0,
                endAngle: 360,
              },
            },
            dataLabels: {
              enabled: false,
            },

            fill: {},

            title: {
              text: "",
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 300,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
        };
        return chartErrorsDetailsMobile;
      }
    },

    plotDataObjectRanking(data, type) {
      // Use this method to prepare data for plots.

      var categories = [];
      var datasets = [];

      var yAxisTxt = this.$t("charts-page.object-analysis-y-axis");

      var dataset = {
        name: "Number",
        data: [],
      };

      //console.log(ds);
      var colorArr = [];
      if (type == 0) {
        data.forEach((element, i) => {
          if (i % 1 === 0) {
            colorArr.push(
              "#" +
              (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6)
            );
            var objectName = "";
            if (this.$i18n.locale == "it-IT" || this.$i18n.locale == "it" ) {
              objectName = element.ita_name;
            } else {
              objectName = element.eng_name;
            }
            categories.push(objectName);

            datasets.push(element.object_count);
          }
        });

        colorArr = [
          "#63b598",
          "#ce7d78",
          "#ea9e70",
          "#a48a9e",
          "#c6e1e8",
          "#648177",
          "#0d5ac1",
          "#f205e6",
          "#1c0365",
          "#14a9ad",
          "#4ca2f9",
          "#a4e43f",
          "#d298e2",
          "#6119d0",
          "#d2737d",
          "#c0a43c",
          "#f2510e",
          "#651be6",
          "#79806e",
          "#61da5e",
          "#cd2f00",
          "#9348af",
          "#01ac53",
          "#c5a4fb",
          "#996635",
          "#b11573",
          "#4bb473",
          "#75d89e",
          "#2f3f94",
          "#2f7b99",
          "#da967d",
          "#34891f",
          "#b0d87b",
          "#ca4751",
          "#7e50a8",
          "#c4d647",
          "#e0eeb8",
          "#11dec1",
          "#289812",
          "#566ca0",
          "#ffdbe1",
          "#2f1179",
          "#935b6d",
          "#916988",
          "#513d98",
          "#aead3a",
          "#9e6d71",
          "#4b5bdc",
          "#0cd36d",
          "#250662",
          "#cb5bea",
          "#228916",
          "#ac3e1b",
          "#df514a",
          "#539397",
          "#880977",
          "#f697c1",
          "#ba96ce",
          "#679c9d",
          "#c6c42c",
          "#5d2c52",
          "#48b41b",
          "#e1cf3b",
          "#5be4f0",
          "#57c4d8",
          "#a4d17a",
          "#be608b",
          "#96b00c",
          "#088baf",
          "#f158bf",
          "#e145ba",
          "#ee91e3",
          "#05d371",
          "#5426e0",
          "#4834d0",
          "#802234",
          "#6749e8",
          "#0971f0",
          "#8fb413",
          "#b2b4f0",
          "#c3c89d",
          "#c9a941",
          "#41d158",
          "#fb21a3",
          "#51aed9",
          "#5bb32d",
          "#21538e",
          "#89d534",
          "#d36647",
          "#7fb411",
          "#0023b8",
          "#3b8c2a",
          "#986b53",
          "#f50422",
          "#983f7a",
          "#ea24a3",
          "#79352c",
          "#521250",
          "#c79ed2",
          "#d6dd92",
          "#e33e52",
          "#b2be57",
          "#fa06ec",
          "#1bb699",
          "#6b2e5f",
          "#64820f",
          "#21538e",
          "#89d534",
          "#d36647",
          "#7fb411",
          "#0023b8",
          "#3b8c2a",
          "#986b53",
          "#f50422",
          "#983f7a",
          "#ea24a3",
          "#79352c",
          "#521250",
          "#c79ed2",
          "#d6dd92",
          "#e33e52",
          "#b2be57",
          "#fa06ec",
          "#1bb699",
          "#6b2e5f",
          "#64820f",
          "#9cb64a",
          "#996c48",
          "#9ab9b7",
          "#06e052",
          "#e3a481",
          "#0eb621",
          "#fc458e",
          "#b2db15",
          "#aa226d",
          "#792ed8",
          "#73872a",
          "#520d3a",
          "#cefcb8",
          "#a5b3d9",
          "#7d1d85",
          "#c4fd57",
          "#f1ae16",
          "#8fe22a",
          "#ef6e3c",
          "#243eeb",
          "#dd93fd",
          "#3f8473",
          "#e7dbce",
          "#421f79",
          "#7a3d93",
          "#635f6d",
          "#93f2d7",
          "#9b5c2a",
          "#15b9ee",
          "#0f5997",
          "#409188",
          "#911e20",
          "#1350ce",
          "#10e5b1",
          "#fff4d7",
          "#cb2582",
          "#ce00be",
          "#32d5d6",
          "#608572",
          "#c79bc2",
          "#00f87c",
          "#77772a",
          "#6995ba",
          "#fc6b57",
          "#f07815",
          "#8fd883",
          "#060e27",
          "#96e591",
          "#21d52e",
          "#d00043",
          "#b47162",
          "#1ec227",
          "#4f0f6f",
          "#1d1d58",
          "#947002",
          "#bde052",
          "#e08c56",
          "#28fcfd",
          "#36486a",
          "#d02e29",
          "#1ae6db",
          "#3e464c",
          "#a84a8f",
          "#911e7e",
          "#3f16d9",
          "#0f525f",
          "#ac7c0a",
          "#b4c086",
          "#c9d730",
          "#30cc49",
          "#3d6751",
          "#fb4c03",
          "#640fc1",
          "#62c03e",
          "#d3493a",
          "#88aa0b",
          "#406df9",
          "#615af0",
          "#2a3434",
          "#4a543f",
          "#79bca0",
          "#a8b8d4",
          "#00efd4",
          "#7ad236",
          "#7260d8",
          "#1deaa7",
          "#06f43a",
          "#823c59",
          "#e3d94c",
          "#dc1c06",
          "#f53b2a",
          "#b46238",
          "#2dfff6",
          "#a82b89",
          "#1a8011",
          "#436a9f",
          "#1a806a",
          "#4cf09d",
          "#c188a2",
          "#67eb4b",
          "#b308d3",
          "#fc7e41",
          "#af3101",
          "#71b1f4",
          "#a2f8a5",
          "#e23dd0",
          "#d3486d",
          "#00f7f9",
          "#474893",
          "#3cec35",
          "#1c65cb",
          "#5d1d0c",
          "#2d7d2a",
          "#ff3420",
          "#5cdd87",
          "#a259a4",
          "#e4ac44",
          "#1bede6",
          "#8798a4",
          "#d7790f",
          "#b2c24f",
          "#de73c2",
          "#d70a9c",
          "#88e9b8",
          "#c2b0e2",
          "#86e98f",
          "#ae90e2",
          "#1a806b",
          "#436a9e",
          "#0ec0ff",
          "#f812b3",
          "#b17fc9",
          "#8d6c2f",
          "#d3277a",
          "#2ca1ae",
          "#9685eb",
          "#8a96c6",
          "#dba2e6",
          "#76fc1b",
          "#608fa4",
          "#20f6ba",
          "#07d7f6",
          "#dce77a",
          "#77ecca",
        ];
        /* REMEMBER: as shown in the guide, each time you have to update the graph,
       you have to redefine object from the outermost element. In this case: the root*/

        this.chartDetails = {
          // eslint-disable no-unused-vars
          theme: {
            mode: "light",
            palette: "palette1",
          },

          /*   */
          colors: colorArr,
          series: datasets,
          labels: categories,
          legend: {
            show: true,
            horizontalAlign: "right",
            position: "right",
            floating: true,
            itemMargin: {},
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              console.log(series)
              console.log(dataPointIndex)
              const dataLabel = w.config.labels[seriesIndex];
              return `<div class="custom-tooltip">${dataLabel}</div>`;
            },
          },
          chartOptions: {
            chart: {
              width: 350,
              type: "donut",
            },
            plotOptions: {
              pie: {
                startAngle: 0,
                endAngle: 360,
              },
            },
            dataLabels: {
              enabled: false,
            },
            fill: {},

            title: {
              text: "",
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 300,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
        };
        this.chartDetailsMobile = {
          // eslint-disable no-unused-vars
          theme: {
            mode: "light",
            palette: "palette1",
          },

          /*   */
          colors: colorArr,
          series: datasets,
          labels: categories,
          legend: {
            show: false,
            horizontalAlign: "right",
            position: "right",
            floating: true,
            itemMargin: {},
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              console.log(series)
              console.log(dataPointIndex)
              const dataLabel = w.config.labels[seriesIndex];
              return `<div class="custom-tooltip">${dataLabel}</div>`;
            },
          },
          chartOptions: {
            chart: {
              width: 350,
              type: "donut",
            },
            plotOptions: {
              pie: {
                startAngle: 0,
                endAngle: 360,
              },
            },
            dataLabels: {
              enabled: false,
            },

            fill: {},

            title: {
              text: "",
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 300,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
        };
      } else if (type == 1) {
        data.forEach((element, i) => {
          if (i % 1 === 0) {
            categories.push(element.day_and_hour);

            dataset.data.push(element.object_count);
          }
        });
        datasets.push(dataset);

        this.chartHourlyDistribution = {
          options: {
            colors: ["#546E7A"],
            chart: {
              id: "Objects hourly distribution",
              type: "bar",
              height: 250,
              zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: "zoom",
              },
            },

            yaxis: {
              title: {
                text: yAxisTxt,
              },
            },
            xaxis: {
              type: "category",
              categories: categories,
              dataLabels: {},
              tickAmount: 15,
              labels: {
                show: true,
                style: {
                  colors: "#000",
                  fontSize: "0.8rem",
                  fontFamily: "Codec Pro , sans-serif",
                },
                formatter: function (val) {
                  return val + ":00";
                },
              },
              axisBorder: { show: true },
              axisTicks: { show: true },
              tooltip: { enabled: true },
            },
            plotOptions: {
              bar: {
                borderRadius: 15,
                borderRadiusApplication: "around",
                borderRadiusWhenStacked: "last",
                columnWidth: "40%",
                barHeight: "50%",
                distributed: false,
                rangeBarOverlap: true,
                rangeBarGroupRows: false,
              },
            },

            dataLabels: { enabled: false },
            stroke: {
              curve: "smooth",
            },
          },
          series: datasets,
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        };
      } else if (type == 2) {
        data.forEach((element, i) => {
          var key = "charts-page." + element.day_name;
          if (i % 1 === 0) {
            categories.push(this.$t(key));

            dataset.data.push(element.object_count);
          }
        });
        datasets.push(dataset);

        this.chartWeekDayDistribution = {
          options: {
            chart: {
              id: "Week objects distribution",
              type: "bar",
              height: 350,
              zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: "zoom",
              },
            },
            yaxis: {
              title: {
                text: yAxisTxt,
              },
              labels: {
                formatter: function (val) {
                  return val.toFixed(0);
                },
              },
            },
            xaxis: {
              type: "category",
              categories: categories,
              dataLabels: {},
              tickAmount: 15,
              labels: {
                show: true,
                style: {
                  colors: "#000",
                  fontSize: "0.8rem",
                  fontFamily: "Codec Pro, sans-serif",
                },
              },
              axisBorder: { show: true },
              axisTicks: { show: true },
              tooltip: { enabled: true },
            },
            plotOptions: {
              bar: {
                borderRadius: 15,
                borderRadiusApplication: "around",
                borderRadiusWhenStacked: "last",
                columnWidth: "40%",
                barHeight: "50%",
                distributed: false,
                rangeBarOverlap: true,
                rangeBarGroupRows: false,
              },
            },

            dataLabels: { enabled: false },
            stroke: {
              curve: "smooth",
            },
          },
          series: datasets,
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        };
      }
      //console.log(this.chartDetails);
    },

    getCurrWeekMonSun() {
      // When displaying date range
      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      var last = first + 6; // last day is the first day + 6

      var firstday = new Date(curr.setDate(first)).toISOString().substr(0, 10);
      var lastday = new Date(curr.setDate(last)).toISOString().substr(0, 10);
      var arr = [];
      arr.push(firstday);
      arr.push(lastday);
      return arr;
    },
  },
  computed: {},
};
</script>
                        
<style></style>
                        
