<template>
  <div>
    <v-sheet v-if="!flippingCards[0].flipped" style="border-radius: 20px;">
      <v-card-title style="height: 14vh;">
        <v-spacer></v-spacer>
        <template>
          <div>
            <p class="font-weight-bold" style="font-size: 1.1rem;">
              <b v-t="'charts-page[\'filling-level\']'"
                style="font-size:1.1rem; display: inline; white-space: break-spaces;">
              </b>
            </p>
          </div>
          <!--<div>
              <v-avatar><v-icon @click="flippingCards[0].flipped = true">mdi-information</v-icon></v-avatar>
            </div>-->
        </template>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider thickness="10px"></v-divider>

      <v-sheet>
        <v-card-text>
          <template><span class="mb-2 font-weight-bold text-uppercase" v-t="'charts-page[\'filling-level-avarage\']'">
            </span><br />
          </template>
          <v-row v-show="(lineChartBinConfig.options &&
            recRateGraphData.length > 0 &&
            !loading) || updating
            ">
            <v-col cols="1" class="d-flex justify-center align-center">
              <v-btn icon @click="addDayAndUpdate(true)">
                <v-icon size="45">mdi-menu-left</v-icon></v-btn></v-col>
            <v-col cols="10">
              <apexchart type="line" :options="lineChartBinConfig.options" :series="lineChartBinConfig.series"
                height="300">
              </apexchart>
              <v-progress-linear indeterminate size="55" v-if="updating" class="mx-auto"
                style="width: 50%"></v-progress-linear>
            </v-col>

            <v-col class="d-flex justify-center align-center" cols="1">
              <v-btn icon @click="addDayAndUpdate" class="d-flex justify-center align-center"><v-icon
                  size="45">mdi-menu-right</v-icon></v-btn></v-col>
          </v-row>

          <v-alert v-if="recRateGraphData.length == 0 && !loading && !updating" outlined dismissible type="error"
            class="mx-auto pt-1" max-width="250px">
            <span v-t="'no-results-generic'"></span>
          </v-alert>
          <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        </v-card-text>
      </v-sheet>
    </v-sheet>
    <!--<transition name="flip">
      <v-sheet style="height: 100%" v-if="flippingCards[0].flipped">
        <v-card-title>
          <v-spacer></v-spacer>

          <template>
            <b class="text-uppercase" v-t="'charts-page[\'filling-level\']'"></b>
            <v-avatar>
              <v-btn icon @click="flippingCards[0].flipped = false">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn></v-avatar>
          </template>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <p v-t="'charts-page[\'filling-level-history-desc\']'"></p>
        </v-card-text>
      </v-sheet>
    </transition>-->
  </div>
</template>


<script>
import { getFillingLevel } from "../../utils/stations_api_client";

export default {
  props: ["selectedStation"],
  async beforeMount() {
    this.refreshChart();
  },
  data() {
    return {
      recRateGraphData: [],
      lineChartBinConfig: {},
      flippingCards: [{ name: "history", flipped: false }],
      loading: true,
      updating: false,
      graphScrollingCounter: 0,
      graphScrollingUpdateThreshold: 5,
      initialScrollingPos: null,
      currentScrollingPos: null,
      dateArr: [],
    };
  },
  watch: {
    selectedStation: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler() {
        // do your stuff
        //console.log(val);
        this.refreshChart();
      },
    },

    $graphsDates: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler() {
        // do your stuff
        this.refreshChart();
      },
    },
  },

  methods: {
    refreshChart() {
      this.loading = true;
      this.activePicker = false;
      this.getFillingLevelTimeFramed(this.$selectedStation).then(() => {
        this.loading = false;
      });
    },

    async getFillingLevelTimeFramed(stationRec) {
      //console.log(stationRec);
      //console.log(stationRec["StationId"]);

      //console.log(this.$graphsDates);
      if (this.$graphsDates[0] != undefined && this.$graphsDates[1] != undefined) {
        this.recRateGraphData = [];

        // TODO: Test with real data.
        // TODO: Fix backend error.
        const myApiData = await getFillingLevel(
          stationRec["StationId"],
          this.$graphsDates[0],
          this.$graphsDates[1]
        );

        var endDate = new Date(this.$graphsDates[1]);
        var today = new Date();
        var diffDays = 0;
        if (endDate > today) {
          var diff = Math.abs(endDate - today);
          diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        }
        this.recRateGraphData = myApiData;
        this.plotFillingLevelBins(this.recRateGraphData, diffDays);
      }
    },
    async getFillingLevelTimeFramedUpdateSeries(stationRec, dates) {
      //console.log(stationRec);
      //console.log(stationRec["StationId"]);

      //console.log(this.$graphsDates);
      if (dates[0] != undefined && dates[1] != undefined) {
        this.updating = true;
        this.recRateGraphData = [];

        // TODO: Test with real data.
        // TODO: Fix backend error.
        const myApiData = await getFillingLevel(
          stationRec["StationId"],
          this.$graphsDates[0],
          this.$graphsDates[1]
        );

        var endDate = new Date(this.$graphsDates[1]);
        var today = new Date();
        var diffDays = 0;
        if (endDate > today) {
          var diff = Math.abs(endDate - today);
          diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        }
        this.recRateGraphData = myApiData;
        this.plotFillingLevelBins(this.recRateGraphData, diffDays);
        this.updating = false;
      }
    },
    filterGraph(target) {
      //this.selectedBin = target; // Frontend refresh
      // Useful when user wants to filter entire data set by material (plastic, paper, etc.)
      //console.log(target);
      var filtered = this.recRateGraphData.filter(
        (value) => value.material_eng === target
      );
      var endDate = new Date(this.$graphsDates[1]);
      var today = new Date();
      var diffDays = 0;
      if (endDate > today) {
        var diff = Math.abs(endDate - today);
        diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
      }
      this.plotFillingLevelBins(filtered, diffDays);
    },

    async addDayAndUpdate(back = false) {
      if (this.dateArr.length == 0) {
        this.dateArr = this.$graphsDates;
      }
      var dateStart = new Date(Date.parse(this.dateArr[0]));
      var dateEnd = new Date(Date.parse(this.dateArr[1]));
      dateEnd.setDate(dateEnd.getDate() + (back === true ? -1 : 1));
      dateStart.setDate(dateStart.getDate() + (back === true ? -1 : 1));
      this.dateArr = [];
      this.dateArr.push(
        dateStart.toISOString().substr(0, 10),
        dateEnd.toISOString().substr(0, 10)
      );
      this.graphScrollingCounter = 0;
      this.currentScrollingPos = null;
      this.initialScrollingPos = null;
      await this.getFillingLevelTimeFramedUpdateSeries(
        this.$selectedStation,
        this.dateArr
      );
    },
    plotFillingLevelBins(data, forecastDays) {
      // Use this method to prepare data for plots.
      var totalForectastDays = forecastDays
      var categories = [];

      //console.log(ds);

      var labels = data.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.ref_date === value.ref_date)
      );
      // console.log(labels);
      var ds = data.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id_tiny === value.id_tiny)
      );

      // console.log(ds);
      labels.forEach((element, i) => {
        if (i % 1 === 0) {
          categories.push(element.ref_date);
        }
      });

      var tmpCat = categories;
      categories = tmpCat.sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });

      var datasets = [];
      const currentLanguage = this.$i18n.locale;
      ds.forEach((item, i) => {
        if (i % 1 === 0) {
          var key = "charts-page." + item.material_eng
          var material_name = currentLanguage == "it-IT" ? item.material_ita : item.material_eng
          var dataset = {
            name: material_name,
            data: [],
          };

          labels.forEach((element) => {
            var record = data.filter(
              (value) =>
                value.material === item.material &&
                value.id_tiny === item.id_tiny &&
                value.ref_date === element.ref_date
            );

            //console.log(record);
            if (record.length > 0) {
              dataset.data.push(record[0].filling_level);
            } else {
              dataset.data.push(0);
            }
          });
          datasets.push(dataset);
        }
      });
      // console.log(datasets);

      /* REMEMBER: as shown in the guide, each time you have to update the graph,
       you have to redefine object from the outermost element. In this case: the root*/
      this.lineChartBinConfig = {
        options: {
          chart: {
            events: {
              click: async function (event, chartContext, config) {
                this.currentScrollingPos = event;
                console.log(
                  this.initialScrollingPos.screenX,
                  this.currentScrollingPos.screenX
                );
                if (
                  this.graphScrollingCounter >=
                  this.graphScrollingUpdateThreshold &&
                  this.currentScrollingPos &&
                  this.initialScrollingPos
                ) {
                  //console.log(chartContext, config.config.xaxis);
                  //console.log(this.$graphsDates);

                  var firstDate = chartContext.data.twoDSeriesX[0];
                  var latestDate =
                    chartContext.data.twoDSeriesX[
                    chartContext.data.twoDSeriesX.length - 1
                    ];
                  console.log(
                    firstDate,
                    latestDate,
                    config.config.xaxis.max,
                    config.config.xaxis.min
                  );

                  if (
                    config.config.xaxis.max >= latestDate &&
                    this.currentScrollingPos.screenX <
                    this.initialScrollingPos.screenX
                  ) {
                    this.addDayAndUpdate();
                  }

                  if (
                    config.config.xaxis.min <= firstDate &&
                    this.currentScrollingPos.screenX >
                    this.initialScrollingPos.screenX
                  ) {
                    this.addDayAndUpdate(true);
                  }
                }
              }.bind(this),
              mouseMove: function (event) {
                if (this.graphScrollingCounter == 0) {
                  this.initialScrollingPos = event;
                }
              }.bind(this),
              scrolled: function () {
                this.graphScrollingCounter++;
              }.bind(this),
              id: "Filling level per period",
              type: "line",
              stacked: false,
              height: 350,
              zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: "zoom",
              },
            },
          },
          forecastDataPoints: {
            count: totalForectastDays
          },
          yaxis: {
            tickAmount: 5,

            max: 100,
            min: 0,
            labels: {
              formatter: function (val) {
                return val.toFixed(2) + "%";
              },
            },
            title: {
              text: this.$t("charts-page.fill-level"),
            },
          },
          xaxis: {
            type: "datetime",
            categories: categories,
            dataLabels: {},
            tickAmount: 15,
            labels: {
              show: true,
              style: {
                colors: "#000",
                fontSize: "15px",
                fontFamily: "Codec Pro, sans-serif",
              },
              format: "dd/MM",
            },
            axisBorder: { show: true },
            axisTicks: { show: true },
            tooltip: { enabled: true },
          },
          dataLabels: { enabled: false },
          stroke: {
            curve: "smooth",
          },
        },
        series: datasets,
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
        },
      };
    },
    getCurrWeekMonSun() {
      // When displaying date range
      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      var last = first + 6; // last day is the first day + 6

      var firstday = new Date(curr.setDate(first)).toISOString().substr(0, 10);
      var lastday = new Date(curr.setDate(last)).toISOString().substr(0, 10);
      var arr = [];
      arr.push(firstday);
      arr.push(lastday);
      return arr;
    },
  },
  computed: {},
};
</script>
                        
<style></style>
                        
