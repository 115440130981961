<template>
  <v-row style="
    max-height: 36vh;
    overflow-y: auto;
    max-width: inherit;
    margin-top: auto;
">
    <v-col cols="12" v-for="(station, sIx) in $selectedBuilding['stations']" v-bind:key="sIx">
      <v-col dark cols="12">
        <v-card v-if="station" elevation="4" style="border-width: 5px" class="darken-1 rounded-lg"
          @click.capture="station['Room'] != ''" @click="setSelectedStation($selectedBuilding['stations'][sIx])"
          :dark="$selectedStation == station">
          <v-row>
            <v-col lg="12" cols="12">
              <span left class="text-uppercase font-weight-bold"
                style="font-size: 0.8rem; padding-left: 15px; padding-right: 15px;">{{
  station["Room"] == "" ||
    station["Room"] == null ||
    station["Room"] == undefined
    ? $t("charts-page.no-name")
    : station["Room"]
                }}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["buildingsStations"],

  data() {
    return {};
  },
  methods: {
    setSelectedStation(s) {
      this.$selectedStation = s;
      //console.log(this.$selectedBuilding);
      this.$root.$emit("updatedSelectedStation", "hi");

      this.$root.$emit("clickedNestedBuilding", "hi");
    },
  },
};
</script>

<style scoped>
.fill-width {
  flex-wrap: wrap;
}

.v-expansion-panel {
  min-width: 16rem;
  min-height: 20%;
  margin-right: 50px;
  margin-left: 50px;
}

.v-expansion-panel-content {
  min-width: 100%;
  min-height: 20%;
}

.v-btn-toggle>.v-btn.v-btn {
  opacity: 1;
}

.theme--dark.v-card:focus::before {
  opacity: 0;
  background-color: white;
}
</style>