<template>
  <v-app>
    <div>
      <v-row>
        <v-col lg="12" cols="12">
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                hide-details></v-text-field>
            </v-card-title>

            <v-data-table :search="search" :headers="headers" :items="users" :items-per-page="8"
              :loading="!finishedLoading" class="elevation-1">
              <template v-slot:item.role_name="{ item }">
                <v-select @change="
  applyButtonEnabled = true;
changeRoleIds;
item.toBeUpdated = true;
                " v-model="item.role_name" item-text="role_name" :items="roleList"
                  :item-value="roleList.id"></v-select>
              </template>

              <template v-slot:item.visibility="{ item }">
                <v-chip-group>
                  <v-row>
                    <v-col cols="12"><v-chip style="" @click="
  selectedUser = item;
showVisibilityDialog = true;
                    ">+</v-chip></v-col>
                    <v-col cols="12" v-for="(vis, ix) in item.visibility" v-bind:key="ix">
                      <v-chip>{{
                        vis.BuildingName
                      }}
                        <v-icon @click="removeStationFromUserVisib(item, ix, vis)" right>
                          mdi-close-circle</v-icon>
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-chip-group>
              </template>
            </v-data-table>
          </v-card>
          <v-dialog absolute transition="dialog-bottom-transition" v-model="showVisibilityDialog">
            <v-card>
              <v-card-title><v-spacer></v-spacer>Add station to
                {{ selectedUser.username }}'s visibility <v-spacer></v-spacer></v-card-title>
              <v-card-text>
                <template>
                  <!-- <v-select
                        @change="
                          applyButtonEnabled = true;
                          changeRoleIds;
                        "
                        v-model="selectedStation"
                        :items="visibilityItems"
                        :item-value="visibilityList.station_id"
                      ></v-select> -->

                  <v-chip-group column multiple active-class="primary--text" width="600"
                    v-model="updateVisibilitySelected">
                    <v-row>
                      <v-col cols="3" v-for="(visItem, vix) in visibilityList" v-bind:key="vix">
                        <v-chip :value="visItem" v-if="visItem.StationName !== null">
                          <!--01/12/2022 Added info about building to better distinguish-->
                          <b>{{ visItem.BuildingName }}</b> :
                          {{ visItem.StationName }}
                        </v-chip></v-col></v-row>
                  </v-chip-group>
                </template>
              </v-card-text>
              <v-card-actions><v-spacer><v-btn @click="
  pushStationToUserVisib(selectedUser, selectedStation);
showVisibilityDialog = false;
              ">Ok</v-btn></v-spacer></v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>

      </v-row>

      <v-row>
        <v-spacer></v-spacer>
        <v-col lg="12" cols="12">
          <v-alert v-if="updateSuccessful" dense dismissible type="success" v-t="'admin-page.apply-changes-success'">
          </v-alert>
          <v-alert v-if="updateError" dense dismissible type="error" v-t="'admin-page.apply-changes-error'">
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col lg="11" cols="10">
          <v-btn :disabled="!applyButtonEnabled" @click.stop="patchUsers(users)"><span
              v-t="'admin-page.apply-changes'"></span></v-btn></v-col></v-row>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import { Auth } from "aws-amplify";
export default {
  async beforeMount() {
    await this.refreshData();
    // console.log(this.users);
  },
  created() {
    this.$scrollTop();
  },
  data() {
    return {
      users: [],
      roleList: [],
      visibilityList: [],
      selectedStation: {},
      finishedLoading: false,
      updateVisibilitySelected: [],
      toBeRemovedStations: [],

      selectedUser: {},

      applyButtonEnabled: false,

      updateSuccessful: false,

      updateError: false,

      showVisibilityDialog: false,
      search: '',
      headers: [
        { text: "Email", value: "email", width: '30%' },
        { text: "Role", value: "role_name", width: '20%' },
        { text: "Username", value: "username", width: '20%' },
        { text: "Visibility", value: "visibility", width: '30%' },
      ],
    };
  },
  computed: {
    visibilityItems() {
      var names = [];
      for (var cont = 0; cont < this.roleList.length; cont++)
        names.push(
          this.visibilityList[cont]["BuildingName"] +
          "\r\n - \r\n" +
          this.visibilityList[cont]["StationName"]
        );

      return names;
    },

    visibilityItemName() {
      var names = [];
      for (var cont = 0; cont < this.roleList.length; cont++)
        names.push(
          this.visibilityList[cont]["BuildingName"] +
          "\r\n - \r\n" +
          this.visibilityList[cont]["StationName"]
        );

      return names;
    },
  },
  methods: {
    async refreshData() {
      await this.getUsers().then(() => {
        this.finishedLoading = true;
      });
      await this.getRoleList().then(() => {
        this.finishedLoading = true;
      });

      await this.getVisibilityList().then(() => {
        this.finishedLoading = true;
      });
    },
    changeRoleIds() {
      this.users.forEach((user) => {
        //console.log(this.roleList);
        user["role_id"] = this.roleList.find(
          (obj) => obj["role_name"] == user["role_name"]
        ).id;
      });
    },
    async getUsers() {
      this.finishedLoading = false;
      this.users = [];
      var endpoint = this.$resource + "readwriteusers";
      var data = { type: "get_people_admin" };
      var token = await (await Auth.currentSession())
        .getIdToken()
        .getJwtToken();
      await axios
        .post(this.$apiUrl + endpoint, data, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          // console.log(response);
          var ls = response["data"]["body"];
          for (var cont = 0; cont < ls.length; cont++) {
            ls[cont].toBeUpdated = false;
            ls[cont].toBeUpdatedVisibility = false;
            this.users.push(ls[cont]);
          }
        });
    },

    showPopupAvailableStations() { },
    pushStationToUserVisib(item) {
      console.log(this.updateVisibilitySelected);
      this.updateVisibilitySelected.forEach((station) => {
        item.visibility.push(station);
      });
      console.log(item);
      item.toBeUpdatedVisibility = true;
      this.applyButtonEnabled = true;
    },

    removeStationFromUserVisib(user, ix, station) {
      user.visibility.splice(ix, 1); // 2nd parameter means remove one item only
      this.toBeRemovedStations.push({
        UserId: user.id,
        StationId: station.StationId,
      });
      //console.log(this.toBeRemovedStations);
      this.applyButtonEnabled = true;
    },

    async getRoleList() {
      this.finishedLoading = false;

      this.roleList = [];
      var endpoint = this.$resource + "readwriteusers";
      var data = { type: "get_role_list" };
      var token = await (await Auth.currentSession())
        .getIdToken()
        .getJwtToken();
      await axios
        .post(this.$apiUrl + endpoint, data, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          var ls = response["data"]["body"];
          // console.log(ls);
          for (var cont = 0; cont < ls.length; cont++) {
            this.roleList.push(ls[cont]);
          }
          //  console.log(this.roleList);
        });
    },

    async getVisibilityList() {
      this.finishedLoading = false;

      this.visibilityList = [];
      var endpoint = this.$resource + "readwriteusers";
      var data = { type: "get_visibility_list" };
      var token = await (await Auth.currentSession())
        .getIdToken()
        .getJwtToken();
      await axios
        .post(this.$apiUrl + endpoint, data, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          var ls = response["data"]["body"];
          //   console.log(ls);
          for (var cont = 0; cont < ls.length; cont++) {
            if (
              ls[cont]["StationName"] !== null &&
              ls[cont]["StationName"].toString().trim() !== ""
            ) {
              this.visibilityList.push(ls[cont]);
            }
          }
          //console.log(this.visibilityList);
        });
    },

    async patchUsers(userList) {
      this.updateSuccessful = false;
      this.updateError = false
      this.changeRoleIds();
      // this.finishedLoading = false;
      //this.updateSuccessful = false;

      for (var cont = 0; cont < userList.length; cont++) {
        var user = userList[cont];
        //console.log(user);
        var endpoint = this.$resource + "readwriteusers";

        var data = { type: "patch_user_role", user: user };
        if (user.toBeUpdated) {
          console.log("update");
          var token = await (await Auth.currentSession())
            .getIdToken()
            .getJwtToken();

          await axios
            .patch(this.$apiUrl + endpoint, data, {
              headers: {
                Authorization: token,
              },
            })
            .then((response) => {
              //console.log(response);
              if (response.status == 200) {
                if (response.data["statusCode"] != 200) {
                  this.updateSuccessful = false;
                  this.updateError = true
                } else {
                  this.updateSuccessful = true;
                  this.updateError = false
                }
              }
            });
          user.toBeUpdated = false;
        }

        if (user.toBeUpdatedVisibility) {
          //console.log("update visibility");
          var dataVis = {
            type: "patch_user_visibility",
            operation: "add",
            user: user,
          };
          //console.log(dataVis);
          token = await (await Auth.currentSession())
            .getIdToken()
            .getJwtToken();
          await axios
            .patch(this.$apiUrl + endpoint, dataVis, {
              headers: {
                Authorization: token,
              },
            })
            .then((response) => {
              // console.log(response);
              if (response.status == 200) {
                if (response.data["statusCode"] != 200) {
                  this.updateSuccessful = false;
                  this.updateError = true
                } else {
                  this.updateSuccessful = true;
                  this.updateError = false
                }
              }
            });
          user.toBeUpdatedVisibility = false;
        }
      }

      if (this.toBeRemovedStations.length > 0) {
        //console.log("delete visibility");
        dataVis = {
          type: "patch_user_visibility",
          operation: "delete",
          station_list: this.toBeRemovedStations,
        };
        // console.log(dataVis);
        token = await (await Auth.currentSession()).getIdToken().getJwtToken();
        await axios
          .patch(this.$apiUrl + endpoint, dataVis, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            // console.log(response);
            if (response.status == 200) {
              if (response.data["statusCode"] != 200) {
                this.updateSuccessful = false;
                this.updateError = true
              } else {
                this.updateSuccessful = true;
                this.updateError = false
              }
            }
          });
        user.toBeUpdatedVisibility = false;
      }
      //Let's refresh all lists above
      // await this.refreshData();
      this.toBeRemovedStations = [];
      window.setTimeout(await this.refreshData(), 1);
    },
  },
};
</script>

<style scoped>
.v-alert {
  position: relative;
  left: 25%;
  width: 50%;
}

.v-chip {
  height: auto !important;
}

.v-chip .v-chip__content {
  max-width: 100%;
  height: auto;
  white-space: normal;
}
</style>