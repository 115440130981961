import { Auth } from "aws-amplify";
import {
  BUILDINGS_API_URL,
  SIGNED_IMAGES_API_URL,
  STATIONS_STATS_API_URL,
} from "../config";

async function resolveRequestHeaders() {
  var token = (await Auth.currentSession()).getIdToken().getJwtToken();
  return new Headers({ Authorization: token });
}

function resolveUrlSearch(params) {
  if (Object.keys(params).length <= 0) return "";
  return `?${new URLSearchParams(params)}`;
}

async function fetchEndpoint(url, params = {}) {
  const res = await fetch(`${url}${resolveUrlSearch(params)}`, {
    headers: await resolveRequestHeaders(),
  });
  return await res.json();
}

function stationEndpointWithDates(endpointName) {
  return async function (stationId, startDate = null, endDate = null) {
    const params = {};
    if (startDate) params.start_date = startDate;
    if (endDate) params.end_date = endDate;
    return await fetchEndpoint(
      `${STATIONS_STATS_API_URL}${stationId}/${endpointName}`,
      params
    );
  };
}

export async function getWeights(stationId, startDate, endDate, by) {
  return await fetchEndpoint(`${STATIONS_STATS_API_URL}${stationId}/weights`, {
    start_date: startDate,
    end_date: endDate,
    by,
  });
}

export async function getObjectCategoryRanking(
  stationId,
  startDate,
  endDate,
  by = null
) {
  const params = { start_date: startDate, end_date: endDate };
  if (by) params.by = by;
  return await fetchEndpoint(
    `${STATIONS_STATS_API_URL}${stationId}/object_category_ranking`,
    params
  );
}

export const getRecyclingRate = stationEndpointWithDates("recycling_rate");
export const getFillingLevel = stationEndpointWithDates("filling_level");
export const getRecyclingPercentage = stationEndpointWithDates(
  "recycling_percentage"
);

function adaptStation(station) {
  return {
    StationId: station.id,
    StationName: station.name,
    Room: station.room,
    latitude: station.latitude,
    longitude: station.longitude,
  };
}

function adaptBuilding(building) {
  return {
    BuildingId: building.id,
    BuildingName: building.name,
    CustomerId: building?.customer?.id,
    CustomerName: building?.customer?.name,
    LogoUrl: building.logo_url,
    latitude: building.latitude,
    longitude: building.longitude,
    stations: building?.stations?.map(adaptStation),
  };
}

export async function getBuildingsWithStations() {
  const buildingsRetrieved = await fetchEndpoint(BUILDINGS_API_URL);
  return buildingsRetrieved.map(adaptBuilding);
}

export async function getSignedImageUrl(imageName) {
  return await fetchEndpoint(`${SIGNED_IMAGES_API_URL}${imageName}`);
}

export async function getStatus(stationId) {
  return await fetchEndpoint(`${STATIONS_STATS_API_URL}${stationId}/status`);
}

export async function getDataRank(stationId) {
  return await fetchEndpoint(`${STATIONS_STATS_API_URL}${stationId}/data_rank`);
}
