<template>
  <div>
    <v-app-bar-nav-icon v-if="isMobileDevice" @click.stop="toggleMenu()" id="navbar-toggle">
      <!--<v-img src="../assets/relearn-logo-only.png" style="background-color: white" width="50" height="auto" />-->
    </v-app-bar-nav-icon>
    <v-navigation-drawer v-if="isMobileDevice" id="navigationBar" elevation="5" v-model="miniVariantClosed" temporary
      absolute>
      <!--Big screen Menu-->
      <v-list-item v-if="!isMobileDevice">
        <v-img src="../assets/relearn.png" contain class="mx-auto" max-width="100" max-heigth="32"></v-img>
      </v-list-item>

      <v-list-item class="px-2">
        <!--<v-list-item-avatar @click.stop="toggleMenu" v-if="isMobileDevice">
          <v-img src="../assets/relearn-logo-only.png" style="background-color: white" max-width="80vw;"
            height="auto" />
        </v-list-item-avatar>-->

        <v-avatar size="100" class="mx-auto elevation-5 mt-5 mb-5" color="white" style="background-color: #00260F;">
          <v-avatar size="60" class="mx-auto" color="black" tile style="border-width: 2px; background-color: #00260F;">
            <v-img v-if="$selectedCustomer" contain :src="$selectedCustomer &&
              $selectedCustomer.encoded_pic != null &&
              $selectedCustomer.encoded_pic != '' &&
              $selectedCustomer.encoded_pic != 'data:image/jpg;base64,'
              ? $selectedCustomer.encoded_pic
              : ''
              " style="background-color: #00260F" />
            <v-img v-if="!$selectedCustomer" contain src="../assets/relearn-logo-only.png"
              style="background-color: #00260F" />
          </v-avatar>
        </v-avatar>
      </v-list-item>

      <v-divider></v-divider>
      <v-container v-if="!isMobileDevice" fluid></v-container>

      <!--Building selector for MOBILE version-->
      <!--<v-list>-->
      <!-- The subsequent val is duplicated, because when the icon is on expansion panel it's misaligned -->
      <!--<v-list-item v-if="miniVariantClosed && isMobileDevice" @click="toggleMenu()">
          <v-list-item-icon> <v-icon>mdi-domain</v-icon></v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-t="'menuItems[-1]'"></v-list-item-title></v-list-item-content>
        </v-list-item>-->

      <!--<v-expansion-panels v-if="!miniVariantClosed && isMobileDevice">
          <v-expansion-panel>
            <v-expansion-panel-header style="text-align: center">
              <v-row>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-office-building-outline</v-icon></v-list-item-icon>

                  <v-list-item-content class="mx-auto">
                    <v-list-item-title v-t="'menuItems[-1]'"></v-list-item-title></v-list-item-content>
                </v-list-item></v-row>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-spacer></v-spacer>
              <v-flex align-self-end>
                <building-and-station-selector v-if="!miniVariantClosed"
                  :buildingsStations="$buildingsStations"></building-and-station-selector></v-flex>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>-->
      <!--</v-list>-->

      <!--Menu content for desktop and bigger versions-->
      <v-list dense>
        <v-list-item v-if="!isMobileDevice" @click="onClickSelectPage(0)">
          <v-list-item-content v-if="!isMobileDevice">
            <v-list-item-title
              class="text-h5 font-weight-bold nav-bar-text">Menu</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-container fluid></v-container>

        <v-list-item @click="onClickSelectPage(0)">

          <v-list-item-icon>
            <v-icon left color="black">mdi-view-grid-outline
            </v-icon></v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-h6 font-weight-bold text-lg-left nav-bar-text"
              v-t="'menuItems[0]'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="adminRole" @click="onClickSelectPage(1)">
          <v-list-item-icon><v-icon left color="black">mdi-account-circle-outline
            </v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-h6 font-weight-bold text-lg-left nav-bar-text"
              v-t="'menuItems[1]'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logOut">
          <v-list-item-icon>
            <v-icon color="red">mdi-logout</v-icon></v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="red--text font-weight-bold nav-bar-text"
              v-t="'menuItems[2]'"></v-list-item-title></v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer v-if="!isMobileDevice" id="navigationBarDesktop" :fixed="true" permanent>
      <!--Big screen Menu-->
      <v-list-item v-if="!isMobileDevice">
        <v-img src="../assets/relearn.png" contain class="mx-auto" max-width="100" max-heigth="32"></v-img>
      </v-list-item>

      <v-list-item class="px-2">

        <v-avatar size="100" class="mx-auto elevation-5 mt-5 mb-5" style="background-color: #00260F;">
          <v-avatar size="60" class="mx-auto" color="black" tile style="border-width: 2px; background-color: #00260F;">
            <v-img v-if="$selectedCustomer" contain :src="$selectedCustomer &&
              $selectedCustomer.encoded_pic != null &&
              $selectedCustomer.encoded_pic != '' &&
              $selectedCustomer.encoded_pic != 'data:image/jpg;base64,'
              ? $selectedCustomer.encoded_pic
              : ''
              " style="background-color: #00260F" />
            <v-img v-if="!$selectedCustomer" contain src="../assets/relearn-logo-only.png"
              style="background-color: #00260F" />
          </v-avatar>
        </v-avatar>
      </v-list-item>

      <v-divider></v-divider>
      <v-container v-if="!isMobileDevice" fluid></v-container>
      <v-list dense>
        <v-list-item v-if="!isMobileDevice" @click="onClickSelectPage(0)">
          <v-col cols="2" v-if="!isMobileDevice">
          </v-col>

          <v-list-item-content v-if="!isMobileDevice">
            <v-list-item-title class="text-h5 font-weight-bold">Menu</v-list-item-title>
          </v-list-item-content>

          <v-col cols="2" v-if="!isMobileDevice">
          </v-col>
        </v-list-item>
        <v-container fluid></v-container>

        <v-list-item @click="onClickSelectPage(0)">
          <v-col cols="1" v-if="!isMobileDevice"></v-col>
          <v-list-item-icon>
            <v-icon left color="black">mdi-view-grid-outline
            </v-icon>
          </v-list-item-icon>
          <v-col cols="1" v-if="!isMobileDevice"></v-col>
          <v-list-item-title class="text-h6 font-weight-bold text-lg-left nav-bar-text" v-t="'menuItems[0]'">
          </v-list-item-title>
          <v-col cols="1" v-if="!isMobileDevice"></v-col>
        </v-list-item>

        <v-list-item v-if="adminRole" @click="onClickSelectPage(1)">
          <v-col cols="1" v-if="!isMobileDevice"></v-col>
          <v-list-item-icon>
            <v-icon left color="black">mdi-account-circle-outline
            </v-icon>
          </v-list-item-icon>
          <v-col cols="1" v-if="!isMobileDevice"></v-col>
          <v-list-item-title class="text-h6 font-weight-bold text-lg-left nav-bar-text" v-t="'menuItems[1]'">
          </v-list-item-title>
          <v-col cols="1" v-if="!isMobileDevice"></v-col>
        </v-list-item>
        <v-container v-if="!isMobileDevice" fluid></v-container>

        <v-list-item @click="logOut">
          <v-col cols="3" v-if="!isMobileDevice"></v-col>
          <v-list-item-icon>
            <v-icon color="red">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="red--text font-weight-bold nav-bar-text">
            <p style="margin-bottom: 0px;" v-t="'menuItems[2]'"></p>
          </v-list-item-title>
          <v-col cols="3" v-if="!isMobileDevice"></v-col>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import { Auth } from "aws-amplify";
import LanguageSwitcherRightDrawer from "../components/LanguageSwitcherRightDrawer.vue";
//import BuildingAndStationSelector from "../components/BuildingAndStationSelector.vue";
import CustomerSelector from "../components/CustomerSelector";
import { getBuildingsWithStations, getSignedImageUrl } from "../utils/stations_api_client";

export default {
  components: {
    LanguageSwitcherRightDrawer,
    //BuildingAndStationSelector,
    CustomerSelector,
  },

  name: "NavPage",
  async beforeMount() {
    try {
      await this.retrieveBuildingsAndStations();
      await this.retrieveCustomerPics();
      //console.log(this.$selectedCustomer);

      this.$root.$off("");
      this.$root.$on("clickedNestedStation", async () => {
        //  console.log(val);
        this.toggleMenu();
      });

      //if (this.isMobileDevice) this.miniVariantClosed = true;
      this.miniVariantClosed = false;
    } catch (error) {
      console.error(error);
    }
  },

  data() {
    return {
      languageMenuOpened: false,
      miniVariantClosed: false,
      showSidepanel: true,
      buildingsStations: [],
      customers: [],
      selectedPage: 0,
      showAdminMenu: false,
    };
  },
  computed: {
    adminRole: function () {
      //console.log(this.$userRole);
      return (
        this.$userRole != "" &&
        this.$userRole["role_name"] &&
        !this.$userRole["role_name"].toString().toLowerCase().includes("user")
      );
    },
  },
  methods: {
    onClickSelectPage(val) {
      this.$emit("update", val);
      this.selectedPage = val;
      this.toggleMenu();
    },
    toggleMenu() {
      if (this.isMobileDevice) {
        this.miniVariantClosed = !this.miniVariantClosed;
      }
    },
    async retrieveBuildingsAndStations() {
      const apiResponseData = await getBuildingsWithStations();

      this.$buildingsStations = apiResponseData;
      this.$customers = [];

      var found = false;
      if (this.$buildingsStations) {
        this.$buildingsStations.every((bs) => {
          var customer = {
            id: bs.CustomerId,
            name: bs.CustomerName,
            logo_url: bs.LogoUrl,
          };
          var index = this.$customers.findIndex((x) => x.id == customer.id);

          if (index === -1) {
            this.$customers.push(customer);
          }
          bs.stations.every((st) => {
            if (found === false) {
              if (st["StationName"] != "") {
                //console.log("watch " + st);

                this.$selectedStation = st;
                this.$selectedBuilding = bs;
                this.$selectedCustomer = {
                  id: bs.CustomerId,
                  name: bs.CustomerName,
                  logo_url: bs.LogoUrl,
                  encoded_pic: "",
                };
                this.$root.$emit("updatedSelectedStation", "hi");
                found = true;
              }
              return true;
            } else {
              return true;
            }
          });
          return true;
        });
      } else {
        this.$selectedStation = null;
      }
    },

    async retrieveCustomerPics() {
      for (const customer of this.$customers) {
        if (customer.logo_url) {
          getSignedImageUrl(customer.logo_url).then((customerLogo) => {
            customer.encoded_pic = customerLogo?.temporal_url;
            if (customer.id == this.$selectedCustomer.id) {
              this.$selectedCustomer.encoded_pic = customer.encoded_pic;
            }
          });
        }
      }
    },

    async logOut() {
      await Auth.signOut().then(() => {
        //console.log("Successfully logged out");
      });
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.fill-width {
  overflow: scroll;
}

.v-expansion-panel {}

.v-navigation-drawer {
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 5px;
}

#navbar-toggle {
  position: absolute;

}

#navigationBarDesktop {
  width: 15vw !important;
  max-width: 240px !important;
}

#navigationBarDesktop .nav-bar-text {
  font-size: 1.0rem !important;
}

@media only screen and (min-width: 2100px) {
  #navigationBarDesktop {
    width: 25vw !important;
    max-width: 400px !important;
  }

  #navigationBarDesktop .nav-bar-text {
    font-size: 1.2rem !important;
    padding-top: 10px;
    ;
  }

}

.v-list--dense .v-list-item .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list-item--dense .v-list-item__title {
  line-height: 2rem !important;
  text-overflow: ellipsis;
}
</style>

