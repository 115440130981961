<template>
  <!--<v-row class="fill-width">
    <v-col cols="12" v-for="(item, ix) in customers" v-bind:key="ix">
      <v-card @click="setSelectedCustomer(item)">
        <v-row offset="20px">
          <v-col cols="4"> <v-icon>mdi-factory</v-icon></v-col
          ><v-col> {{ item["name"] }}</v-col></v-row
        ></v-card
      ></v-col
    >
  </v-row>-->
  <v-row class="mt-10">
    <v-col cols="12">
      <v-select v-if="!isMobileDevice" id="customerSelectionButton" style="opacity:100%; font-size: 0.8rem;"
        :items="$customers" v-model="$selectedCustomer" item-text="name" @input="setSelectedCustomer" solo class="
          floating-expansion-panels
          text-center
          font-weight-bold
          text-uppercase
          rounded-lg         
        " return-object>
        <template v-slot:prepend-inner class="">
          <v-avatar size="40" elevation="4" color="white" class="logo-class" style="margin-bottom: 5px; background-color: #00260F;">
            <v-img style="background-color: #00260F;" sizes="40" contain :src="$selectedCustomer.encoded_pic" /></v-avatar>
        </template>
        <template v-slot:append>
          <v-avatar size="32" color="white" class="" style="">
            <v-icon contain size="30" class="pb-2"
              :src="$selectedCustomer.encoded_pic">mdi-menu-down</v-icon></v-avatar>
        </template>
      </v-select>
      <v-select v-if="isMobileDevice" id="customerSelectionButton" style="opacity:100%; font-size: 0rem; width: 65%;"
        :items="$customers" v-model="$selectedCustomer" item-text="name" @input="setSelectedCustomer" solo class="
          floating-expansion-panels
          text-center
          font-weight-bold
          text-uppercase
          rounded-lg         
        " return-object>
        <template v-slot:prepend-inner class="">
          <v-avatar size="40" elevation="4" color="white" class="logo-class" style="margin-bottom: 5px; background-color: #00260F;">
            <v-img style="background-color: #00260F;" sizes="40" contain :src="$selectedCustomer.encoded_pic" /></v-avatar>
        </template>
        <template v-slot:append>
          <v-avatar size="32" color="white" class="" style="">
            <v-icon contain size="30" class="pb-2"
              :src="$selectedCustomer.encoded_pic">mdi-menu-down</v-icon></v-avatar>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  beforeMount() {
    //console.log(this.$customers);
  },

  data() {
    return {};
  },
  methods: {
    setSelectedCustomer() {
      // console.log("triggered");
      //if (b.stations) this.$selectedStation = b.stations[0];

      //console.log(this.$selectedCustomer);

      var index = this.$buildingsStations.findIndex(
        (x) => x.CustomerId == this.$selectedCustomer.id
      );

      if (index !== -1) {
        this.$selectedBuilding = this.$buildingsStations[index];
        this.$selectedStation = this.$selectedBuilding.stations[0];
        this.$root.$emit("updatedSelectedBuilding", "hi");
        this.$root.$emit("updatedSelectedStation", "hi");
      }

      this.$root.$emit("updatedSelectedCustomer", "hi");
    },
  },
  computed: {
    listCompanies() {
      var companies = [];
      for (var cont = 0; cont < this.roleList.length; cont++)
        companies.push(this.roleList[cont]["role_name"]);

      return companies;
    },
  },
};
</script>

<style scoped>
.fill-width {}

.v-expansion-panel {
  min-width: 16rem;
  min-height: 20%;
  margin-right: 50px;
  margin-left: 50px;
}

.v-expansion-panel-content {
  min-width: 100%;
  min-height: 20%;
}

.logo-class {
  max-width: 100px;
  max-height: 100px;
  border: 0.5px solid rgb(190, 190, 190);
  border-color: rgb(209, 209, 209) !important;
  z-index: 10 !important;
  box-shadow: 2px 2px 10px #ccc;
}
</style>