<template>
  <v-row>
    <v-spacer></v-spacer>
    <v-col lg="12" cols="12">
      <v-app-bar class="app_bar" tile extension-height="100%" style="border: 0; box-shadow: none" color="transparent">
        <v-col class="" cols="7" lg="7" sm="7" style="width: 100%;">
          <v-app-bar-title style="width: 100%;" class="
              text-h8
              nandocolor--text            
              font-weight-bold
              text-lg-left
              text-sm-left
              text-xs-left
              text-md-left
              text-xl-left
              mt-5
            ">NANDO DASHBOARD<br>
            <span class="text-h6 black--text font-weight-bold">Waste Analytics
            </span>
          </v-app-bar-title>
        </v-col>
        <v-col cols="4" lg="4" sm="4">
          <customer-selector></customer-selector>
        </v-col>
        <v-col cols="1" lg="1" sm="1">
          <v-btn icon class="floating-btn" @click="languageMenuOpened = !languageMenuOpened">
            <v-icon style="color: black;">mdi-flag</v-icon>
          </v-btn>
        </v-col>
      </v-app-bar>

      <language-switcher-right-drawer :languageMenuOpened="toggleOpenSwitch"
        @update="languageMenuOpened = false"></language-switcher-right-drawer>
    </v-col>
  </v-row>
</template>
      
    
<script>
import CustomerSelector from "../components/CustomerSelector.vue";
import LanguageSwitcherRightDrawer from "../components/LanguageSwitcherRightDrawer.vue";

export default {
  components: { CustomerSelector, LanguageSwitcherRightDrawer },
  data() {
    return {
      languageMenuOpened: false,
    };
  },
  computed: {
    toggleOpenSwitch() {
      return this.languageMenuOpened;
    },
  },
};
</script>
    
<style>
@media only screen and (max-width: 900px) {
  .app_bar {
    margin-top: 5vh;
  }
}

.v-app-bar-title__content {
  width: 100% !important;
}
</style>