<template>
  <div>
    <v-sheet style="border-radius: 20px;">
      <v-card-title style="height: 14vh;"><v-spacer></v-spacer>
        <template>
          <div>
            <p class=" font-weight-bold text-uppercase" v-t="'charts-page[\'rec-rate-history\']'"
              style="font-size:1.1rem; display: inline; white-space: nowrap;"><b>
              </b></p>
            <p class="font-weight-bold text-uppercase" style="font-size:1.2rem; display: inline; white-space: nowrap;">
            </p>
          </div>
          <div>
            <v-avatar><!--<v-icon @click="flippingCards[0].flipped = true">mdi-information</v-icon>--><v-icon @click.stop="
            plotDataRecPerBins(recPerGraphData);
            ">mdi-refresh</v-icon></v-avatar>
          </div>
        </template>
        <v-spacer></v-spacer></v-card-title>
      <v-divider thickness="10px"></v-divider>

      <v-sheet style="border-radius: 20px;">
        <v-card-text>
          <v-row v-show="(lineChartRRPerConfig.options &&
            recPerGraphData.size > 0 &&
            !loading) ||
            updating
            ">
            <v-col cols="1" class="d-flex justify-center align-center">
              <v-btn icon @click="addDayAndUpdate(true)">
                <v-icon size="45">mdi-menu-left</v-icon></v-btn></v-col>

            <v-col name="graph-col" cols="10">
              <apexchart ref="chart" type="line" :options="lineChartRRPerConfig.options"
                :series="lineChartRRPerConfig.series">
              </apexchart>
              <v-progress-linear indeterminate size="55" v-if="updating" class="mx-auto"
                style="width: 50%"></v-progress-linear>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="1">
              <v-btn icon @click="addDayAndUpdate" class="d-flex justify-center align-center"><v-icon
                  size="45">mdi-menu-right</v-icon></v-btn></v-col>
          </v-row>

          <v-alert v-if="recPerGraphData.size == 0 && !loading && !updating" outlined dismissible type="error"
            class="mx-auto" max-width="250px">
            <span v-t="'no-results-generic'"></span>
          </v-alert>
          <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        </v-card-text>
      </v-sheet>
    </v-sheet>
  </div>
</template>


<script>
import { getRecyclingPercentage } from "../../utils/stations_api_client";

export default {
  props: ["selectedBin", "selectedStation"],
  async beforeMount() {
    this.refreshChart();
  },
  data() {
    return {
      recPerGraphData: new Map(),
      lineChartRRPerConfig: {},
      flippingCards: [{ name: "history", flipped: false }],
      loading: true,
      updating: false,
      graphScrollingCounter: 0,
      graphScrollingUpdateThreshold: 5,
      initialScrollingPos: null,
      currentScrollingPos: null,
      dateArr: [],
    };
  },
  watch: {
    selectedStation: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler() {
        // do your stuff
        // console.log(val);
        this.refreshChart();
      },
    },
    $graphsDates: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler() {
        // do your stuff
        this.dateArr = [];
        this.refreshChart();
      },
    },
  },

  methods: {
    refreshChart() {
      this.loading = true;
      this.activePicker = false;
      this.getStationRecyclingPercentageTimeFramed(this.$selectedStation).then(() => {
        this.loading = false;
      });
    },

    async getStationRecyclingPercentageTimeFramed(stationRec) {
      if (this.$graphsDates[0] != undefined && this.$graphsDates[1] != undefined) {
        this.recPerGraphData = new Map();

        const apiResponseData = await getRecyclingPercentage(
          stationRec["StationId"],
          this.$graphsDates[0],
          this.$graphsDates[1]
        );

        this.recPerGraphData = new Map(Object.entries(apiResponseData));
        var endDate = new Date(this.$graphsDates[1]);
        var today = new Date();
        var diffDays = 0;
        if (endDate > today) {
          var diff = Math.abs(endDate - today);
          diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        }
        this.plotDataRecPercentage(this.recPerGraphData, diffDays);
      }
    },
    async getStationRecyclingPercentageTimeFramedUpdateSeries(stationRec, dates) {
      if (dates[0] != undefined && dates[1] != undefined) {
        this.updating = true;
        this.recPerGraphData = new Map();

        const apiResponseData = await getRecyclingPercentage(
          stationRec["StationId"],
          this.$graphsDates[0],
          this.$graphsDates[1]
        );

        this.recPerGraphData = new Map(Object.entries(apiResponseData));
        var endDate = new Date(this.$graphsDates[1]);
        var today = new Date();
        var diffDays = 0;
        if (endDate > today) {
          var diff = Math.abs(endDate - today);
          diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        }
        this.plotDataRecPercentage(this.recPerGraphData, diffDays);
        this.updating = false;
      }
    },
    filterGraph(target) {
      this.selectedBin = target; // Frontend refresh
      // Useful when user wants to filter entire data set by material (plastic, paper, etc.)
      //console.log(target);
      var filtered = this.recPerGraphData.filter(
        (value) => value.material_eng === target
      );
      this.plotDataRecPerBins(filtered, 0);
    },

    async addDayAndUpdate(back = false) {
      if (this.dateArr.length == 0) {
        this.dateArr = this.$graphsDates;
      }
      var dateStart = new Date(Date.parse(this.dateArr[0]));
      var dateEnd = new Date(Date.parse(this.dateArr[1]));
      dateEnd.setDate(dateEnd.getDate() + (back === true ? -1 : 1));
      dateStart.setDate(dateStart.getDate() + (back === true ? -1 : 1));
      this.dateArr = [];
      this.dateArr.push(
        dateStart.toISOString().substr(0, 10),
        dateEnd.toISOString().substr(0, 10)
      );
      this.graphScrollingCounter = 0;
      this.currentScrollingPos = null;
      this.initialScrollingPos = null;
      await this.getStationRecyclingPercentageTimeFramedUpdateSeries(
        this.$selectedStation,
        this.dateArr
      );
    },

    plotDataRecPercentage(dataInput, forecastDays) {
      // Use this method to prepare data for plots.

      var totalForectastDays = forecastDays

      var datasets = [];
      var x_labels = [];

      var dataset_internal_rr = {
              name: "Recycling rate",
              data: [],
            };

      var dataset_internal_trr = {
        name: "Ideal Recycling Rate",
        data: [],
      };

      dataInput = [...dataInput.values()];
      dataInput = dataInput.sort((a, b) => a.ref_date < b.ref_date ? -1 : 0);
  
      dataInput.forEach((element) =>{
          x_labels.push(element.ref_date);
          dataset_internal_rr.data.push(element.recycling_rate);
          dataset_internal_trr.data.push(element.target_recycling_rate);
      });

      datasets.push(dataset_internal_rr);
      datasets.push(dataset_internal_trr);
      

      /* REMEMBER: as shown in the guide, each time you have to update the graph,
       you have to redefine object from the outermost element. In this case: the root*/
      this.lineChartRRPerConfig = {
        options: {
          chart: {
            events: {
              click: async function (event, chartContext, config) {
                this.currentScrollingPos = event;
                //console.log(                  this.initialScrollingPos.screenX,                   this.currentScrollingPos.screenX                 );
                if (
                  this.graphScrollingCounter >=
                  this.graphScrollingUpdateThreshold &&
                  this.currentScrollingPos &&
                  this.initialScrollingPos
                ) {
                  //console.log(chartContext, config.config.xaxis);
                  //console.log(this.$graphsDates);

                  var firstDate = chartContext.data.twoDSeriesX[0];
                  var latestDate =
                    chartContext.data.twoDSeriesX[
                    chartContext.data.twoDSeriesX.length - 1
                    ];
                  //console.log(
                  //  firstDate,
                  // latestDate,
                  // config.config.xaxis.max,
                  // config.config.xaxis.min
                  //);

                  if (
                    config.config.xaxis.max >= latestDate &&
                    this.currentScrollingPos.screenX <
                    this.initialScrollingPos.screenX
                  ) {
                    this.addDayAndUpdate();
                  }

                  if (
                    config.config.xaxis.min <= firstDate &&
                    this.currentScrollingPos.screenX >
                    this.initialScrollingPos.screenX
                  ) {
                    this.addDayAndUpdate(true);
                  }
                }
              }.bind(this),
              mouseMove: function (event) {
                if (this.graphScrollingCounter == 0) {
                  this.initialScrollingPos = event;
                }
              }.bind(this),
              scrolled: function () {
                this.graphScrollingCounter++;
              }.bind(this),
            },
            id: "Recycling rate per period",
            type: "line",
            stacked: false,
            height: 350,
            zoom: {
              type: "x",
              enabled: true,
              autoScaleYaxis: true,
            },
            toolbar: {
              autoSelected: "zoom",
            },
          },
          forecastDataPoints: {
            count: totalForectastDays
          },
          yaxis: [
            {
              opposite: false,
              tickAmount: 5,
              max: 1,
              min: 0,
              labels: {
                opposite: true,
                formatter: function (val) {
                  return (val * 100).toFixed(2) + "%";
                },
                align: "right",
              },
              title: {
                text: "Recycling rate",
              },
            },
          ],
          xaxis: {
            type: "datetime",
            categories: x_labels,
            dataLabels: {},
            tickAmount: 30,
            labels: {
              show: true,
              style: {
                colors: "#000",
                fontSize: "15px",
                fontFamily: "Codec Pro, sans-serif",
              },
              format: "dd/MM",
            },
            axisBorder: { show: true },
            axisTicks: { show: true },
            tooltip: { enabled: true },
          },
          dataLabels: { enabled: false },
          stroke: {
            curve: "smooth",
          },
        },
        series: datasets,
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
        },
      };
    },
  },
  computed: {},
};
</script>
                        
<style></style>
                        
